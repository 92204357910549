import { useEffect, useState } from 'react'
import { Session } from '../session'
import { supabase } from '../supabase'
import { formatDuration, formatISO8601Date } from '../utils/time'
import { Link, useSearchParams } from 'react-router-dom'
import { modeLabel } from '../modes'
import { useIsMobile } from '../utils/useIsMobile'
import theme from '../styles/theme'

interface JournalEntryMeta {
  id: string
  title: string
  duration: number
  created_at: string
  mode: string
}

const firstPageLength = 10

export default function JournalEntries({ session }: { session: Session }) {
  const [entries, setEntries] = useState<JournalEntryMeta[]>()
  const [showAll, setShowAll] = useState(false)
  const [params] = useSearchParams()
  const showUnsummarized = !!params.get('unsummarized')

  useEffect(() => {
    const fetchJournalEntries = async () => {
      try {
        const { data, error } = await supabase
          .from('conversations')
          .select('*, summaries(*)')
          .order('created_at', { ascending: false })

        if (error || !data) {
          throw error || new Error('No data')
        }

        const entries = data
          .map(convo => {
            const summary = convo.summaries[0]
            if (!summary && !showUnsummarized) return null
            return {
              title: summary?.title || 'Untitled',
              created_at: convo.created_at,
              duration: convo.metadata?.duration,
              id: convo.id,
              mode: convo.prompt,
            } as JournalEntryMeta
          })
          .filter(x => x)
        setEntries(entries as JournalEntryMeta[])
      } catch (error) {
        // TODO: handle error
        console.error(error)
      }
    }

    fetchJournalEntries()
  }, [session])

  const isMobile = useIsMobile()

  if (!entries) {
    return <div className="text-center text-lg mt-[10vh]">Loading...</div>
  }

  const endIndex = showAll ? entries.length : firstPageLength

  return (
    <div className="w-[90%] max-w-[900px] mx-auto font-inter text-[16px] xs:w-full flex flex-col gap-3 mt-6">
      {entries.slice(0, endIndex).map(entry => (
        <Link
          to={`/journal/${entry.id}`}
          key={entry.id}
          className="block bg-ltgray px-4 xs:px-8 py-3 rounded-2xl cursor-pointer xs:py-6 xs:bg-white xs:border-[#D0D5DD] xs:hover:border-[#6172F3] xs:border-[1px]"
        >
          <div className="text-left">
            <h3 className="text-xl font-satoshi">{entry.title}</h3>
          </div>
          <div className="flex mt-1 text-textgray justify-between text-left flex-wrap">
            <div>
              {formatISO8601Date(new Date(entry.created_at))} •{' '}
              {modeLabel(entry.mode, isMobile)}
            </div>
            <div>{formatDuration(entry.duration, isMobile)}</div>
          </div>
        </Link>
      ))}
      {!showAll && entries.length > firstPageLength && (
        <div>
          <button
            className={`${theme.button.basic} text-blurple`}
            onClick={() => setShowAll(true)}
          >
            Show All
          </button>
        </div>
      )}

      <div className="text-textgray">
        If you have just ended a session, it may take 5-10 minutes to appear
        here.
      </div>
    </div>
  )
}
