import { useEffect, useState } from 'react'
import { analytics } from '../analytics'
import theme from '../styles/theme'
import BulletList from '../components/BulletList'
import BackButton from './BackButton'
import { Link } from 'react-router-dom'

const bulletlistItems = [
  <span>
    The content of your conversation may be shared with third-party service
    providers in order to run our service — for example, voice transcription
    APIs. Your identifying information (i.e. email address) is not shared.{' '}
    <Link to="/privacy" target="_blank" className="text-blurple">
      Privacy Policy
    </Link>
  </span>,
  'This is not a substitute for therapy. If you are experiencing severe mental distress, please seek professional support.',
]

export default function Terms({
  setAllowContinue,
  onBack,
  inline = true,
}: {
  setAllowContinue?: (allow: boolean) => void
  onBack?: () => void
  inline?: boolean
}) {
  const [checked, setChecked] = useState(!!localStorage.acceptedTerms)

  useEffect(() => {
    analytics.track('Terms And Conditions Shown')
  }, [])

  const handleChange = () => {
    setChecked(!checked)
    if (setAllowContinue) setAllowContinue(!checked)
  }

  return (
    <div className={`${inline ? '' : theme.panel.basic}`}>
      {inline && onBack && <BackButton onBack={onBack} />}
      <div className={`text-center ${inline && `mt-4`}`}>
        <h2 className={`${theme.text.h2} mb-[50px]`}>Terms of Service</h2>
      </div>
      <BulletList
        items={bulletlistItems}
        checkClassName="w-[32px] h-[32px] min-w-[32px]"
      />
      {inline && (
        <>
          <div className="boxes w-fit m-auto mt-8 mb-4 px-[20px] py-[14px] border rounded-[8px] border-[#e3e3e5] flex items-center text-xl">
            <input
              type="checkbox"
              checked={checked}
              className="w-[22px] h-[22px]"
              onChange={handleChange}
              id="terms-checkbox"
            />
            <label
              className="ml-5 text-[#344054] text-[16px]"
              htmlFor="terms-checkbox"
            >
              By checking here, I agree to the above Terms of Service.
            </label>
          </div>
        </>
      )}
    </div>
  )
}
