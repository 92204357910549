import { FaArrowRight } from 'react-icons/fa'

interface VideoLinkCardProps {
  imgSrc?: string
  title: string
  overlayText?: string
  duration: string
  action: () => void
}

export default function VideoLinkCard({
  imgSrc = '/images/background.jpg',
  title,
  overlayText,
  duration,
  action,
}: VideoLinkCardProps) {
  return (
    <div
      onClick={action}
      className="w-full h-[214px] rounded-3xl flex flex-col overflow-hidden"
    >
      <div
        className={`relative grow bg-cover bg-center`}
        style={{ backgroundImage: `url(${imgSrc})` }}
      >
        <div className="flex absolute w-full bottom-0 text-white p-5 pb-2 justify-between items-baseline">
          <span className="text-xl font-bold">{overlayText}</span>
          <span>{duration}</span>
        </div>
      </div>
      <div className="flex justify-between px-5 py-3 bg-ltgray items-center">
        <div className="font-satoshi text-[18px]">{title}</div>
        <div className="bg-blurple rounded-full w-[38px] h-[38px] flex justify-center items-center">
          <span className=" text-white">
            <FaArrowRight size={18} />
          </span>
        </div>
      </div>
    </div>
  )
}
