import { Fragment, useEffect, useState } from 'react'
import { HiMiniArrowSmallRight } from 'react-icons/hi2'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'

import { analytics, openTally } from '../analytics'
import { modes, devModes, useCases, mainSession } from '../modes'
import { useSessionMaybe } from '../session'
import theme from '../styles/theme'

import SignupModal from '../components/SignupModal'
import VideoLinkCard from '../components/VideoLinkCard'
import { FaPlay } from 'react-icons/fa'
import VideoModal from '../components/VideoModal'
import { useIsMobile } from '../utils/useIsMobile'
import { supabase } from '../supabase'
import { InsightsModal } from '../insights/InsightsModal.tsx'
import insightsLogo from '../insights/insights.png'
// import { NewLabel } from '../insights/NewLabel.tsx'

const SHOW_TUTORIAL_VIDEO = true
const SESSION_COUNT_SKIP_ONBOARDING = 2

export default function Home() {
  const session = useSessionMaybe()
  const navigate = useNavigate()
  const [showSignup, setShowSignup] = useState(false)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [showInsightsModal, setShowInsightsModal] = useState(false)
  const [sessionCount, setSessionCount] = useState(0)
  const [params] = useSearchParams()

  const showGuestSignUp = !!params.get('show-guest-signup')

  const modesToShow = localStorage.developer
    ? useCases
    : useCases.filter(c => !c.hidden)

  const toggleLocalBackend = (val: string) => {
    if (val === '') {
      delete localStorage.developerBackendOverride
    } else {
      localStorage.developerBackendOverride = val
    }
  }

  const pickMode = (value: string) => {
    const mode = [...modes, ...devModes, ...useCases, mainSession].find(
      m => m.label === value
    )

    if (!mode) throw new Error(`No mode matches "${value}"`)

    window.localStorage.setItem('lastMode', mode.label)
    analytics.track('Session Selected', { sessionName: mode.label })

    if (sessionCount >= SESSION_COUNT_SKIP_ONBOARDING && !localStorage.noSkipOnboarding) {
      navigate(`/talk?mode=${mode.prompt}`)
    } else {
      navigate(`/start?mode=${mode.prompt}`)
    }
  }

  const developer = !!localStorage.developer
  const isMobile = useIsMobile()

  useEffect(() => {
    if (!isMobile && !localStorage.openedVideo && SHOW_TUTORIAL_VIDEO) {
      setShowVideoModal(true)
      localStorage.openedVideo = 1
    }
  }, [])

  useEffect(() => {
    if (showGuestSignUp) {
      setShowSignup(true)
    }
  }, [showGuestSignUp])

  // Fetch session counts for logged in user
  useEffect(() => {
    const fetchSessionCount = async () => {
      try {
        const { count, error } = await supabase
          .from('conversations')
          .select('*', { count: 'exact' });

        if (error || count === null) {
          throw error || new Error('No data')
        }

        setSessionCount(count)
      } catch (error) {
        // fail silently
        console.error('error in fetchSessionCount', error)
      }
    }
    if (session) {
      fetchSessionCount()
    }
  }, [session])

  const handleVideoButton = () => {
    setShowVideoModal(true)
    analytics.track('Tapped Tutorial Video Button')
  }

  const handleInsightsButton = () => {
    setShowInsightsModal(true)
    analytics.track('Tapped Insights Button')
  }

  return (
    <div className={`${theme.panel.basic} md:w-full px-5 pt-0 relative`}>
      <SignupModal
        isOpen={showSignup}
        onClose={() => setShowSignup(false)}
        isGuestSignup={showGuestSignUp}
      />
      <VideoModal
        isOpen={!!showVideoModal}
        mode={isMobile ? 'bare' : 'full'}
        onClose={() => setShowVideoModal(false)}
      />
      <InsightsModal
        isOpen={showInsightsModal}
        onClose={() => setShowInsightsModal(false)}
      />
      {/* Insights buttons */}
      {session && (
        <>
          {/* Insights Button Mobile */}
          <button
            className={`bg-[#F2F4F7] text-[18px] mt-2 mb-4 flex flex-row justify-center items-center rounded-lg h-[46px] sm:hidden`}
            onClick={handleInsightsButton}
          >
            <img
              alt='Insights'
              className='w-[24px] h-[24px] mr-3'
              src={insightsLogo}
            />
            <span className="text-blurple mr-1 font-bold">Insights:</span> view your insights
            {/* <NewLabel classNames={"right-[10px] top-[0px] h-[22px] w-[42px]"} /> */}
          </button>
          {/* Insights Button Desktop */}
          <div className="absolute z-10 top-[10px] text-[16px] mb-[-10px] max-xs:hidden right-[20px] md:right-[40px] md:top-[30px]">
            <button
              className={`${theme.button.basic} relative flex flex-row items-center rounded-full h-[56px] px-6`}
              onClick={handleInsightsButton}
            >
              <img
                alt='Insights'
                className='w-[24px] h-[24px] mr-3'
                src={insightsLogo}
              />
              Insights
              {/* <NewLabel classNames={"right-[-10px] top-[-10px] h-[22px] w-[44px]"}/> */}
            </button>
          </div>
        </>
      )}
      {SHOW_TUTORIAL_VIDEO && (
        <div className="relative left-[-5px] top-[10px] mb-[-10px] max-xs:hidden md:top-[-5px]">
          <button
            className={`${theme.button.basic} flex flex-row items-center rounded-full h-[56px] px-6`}
            onClick={handleVideoButton}
          >
            Watch Tutorial
            <FaPlay className="ml-3" />
          </button>
        </div>
      )}

      <div className="md:w-[90%] lg:w-[88%] xl:w-[78%] m-auto">
        <div className="text-left xs:text-center mt-0 xs:mt-4">
          <h2 className={`max-xs:hidden ${theme.text.h2}`}>Welcome!</h2>
          <h3
            className={`${theme.text.h3} mt-4 m-auto max-w-[500px] hidden xs:block`}
          >
            Start an{' '}
            <span className="text-textblack font-satoshi-bold">
              Open Session
            </span>{' '}
            by tapping "Begin Session", or choose a specific{' '}
            <span className="text-textblack font-satoshi-bold">
              Session Topic
            </span>{' '}
            below.
          </h3>
        </div>

        {/* MOBILE SIZE CARDS RENDERING */}
        <div className="pt-2 xs:hidden flex flex-col gap-8">
          {SHOW_TUTORIAL_VIDEO && (
            <div
              onClick={handleVideoButton}
              className={`w-full h-[192px] cursor-pointer border-none rounded-3xl overflow-hidden relative bg-cover bg-center p-4 text-white`}
              style={{
                backgroundImage: `url('./images/video-preview.jpg')`,
              }}
            >
              <div className="absolute inset-0 bg-black bg-opacity-30"></div>
              <div className="relative h-full grid grid-rows-[20px_auto_40px] z-20">
                <span className="text-right">3 mins</span>
                <div className="flex justify-center items-center translate-x-[2px] translate-y-[12px]">
                  <FaPlay size={40} />
                </div>
                <span className="text-lg font-satoshi-bold text-center">
                  Watch tutorial before starting
                </span>
              </div>
            </div>
          )}
          <VideoLinkCard
            title="Start Session"
            overlayText="Open Session"
            duration="10+ mins"
            action={() => {
              pickMode(mainSession.label)
            }}
          />
        </div>

        {/* DESKTOP SIZE ORB RENDERING */}
        <div className="hidden xs:flex flex-col items-center relative mt-4">
          <div className="max-w-[300px]">
            <video autoPlay loop muted playsInline disableRemotePlayback>
              <source src="/images/ai-crop.mp4" type="video/mp4" />
            </video>
          </div>
          <button
            className={`${theme.button.fancy} text-lg h-[44px] px-8 absolute`}
            style={{ top: 'calc(50% - 22px)' }}
            onClick={() => pickMode(mainSession.label)}
          >
            Begin Session
          </button>
        </div>

        <div className={`${theme.text.pageTitle} mt-12 max-xs:text-left`}>
          Session Topics
        </div>
        <div className="mt-5 xs:mt-10 mb-10">
          {developer && (
            <div className="flex flex-wrap gap-2 items-center mb-4">
              {devModes.map(m => (
                <button
                  key={m.label}
                  className={theme.button.basic}
                  onClick={() => pickMode(m.label)}
                >
                  {m.label}
                </button>
              ))}
              <div className="ml-3">
                Backend:
                <select
                  className="border border-[#d0d5dd] rounded-[4px] ml-2 p-1"
                  onChange={e => toggleLocalBackend(e.target.value)}
                  defaultValue={localStorage.developerBackendOverride || ''}
                >
                  <option value="">Default</option>
                  <option value="dev21">dev21</option>
                  <option value="dev22">dev22</option>
                  <option value="local">Local</option>
                  <option value="lb">Load-Balanced</option>
                </select>
              </div>
            </div>
          )}

          {/* FRAGMENTS RENDERING */}
          <div className="grid lg:grid-cols-3 grid-rows-auto grid-cols-2 gap-3 xs:gap-6">
            {modesToShow.map((mode, index) => (
              <Fragment key={mode.label}>
                <div
                  onClick={() => pickMode(mode.label)}
                  className="group/mode xs:border border-[#D0D5DD] rounded-[9px] xs:p-[16px] cursor-pointer hover:shadow-[#1018281a] hover:border-[#6172F3] transition-all max-xs:bg-ltgray"
                >
                  <img
                    src={mode.img}
                    className="rounded-[8px] max-xs:rounded-b-none w-[100%]"
                  />
                  <div className="text-left xs:mt-3 max-xs:p-2">
                    <h4 className={theme.text.cardTitle}>{mode.label}</h4>
                    <div className="max-xs:hidden text-[#667085] text-lg mt-2 font-light min-h-[72px]">
                      {mode.description}
                    </div>
                  </div>
                  <div className="max-xs:hidden flex text-left text-lg font-medium text-[#6172F3]">
                    <span className="pe-0.4">Begin Session</span>
                    <HiMiniArrowSmallRight className="text-blurple h-6 w-6 group-hover/mode:translate-x-1.5 transition transition-all duration-11900" />
                  </div>
                </div>
                {/* add a spacer div to center the last item */}
                {modesToShow.length === 7 && index === 5 && (
                  <div className="hidden md:block">&nbsp;</div>
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="text-center text-[#667085] font-normal flex flex-row gap-6 justify-center flex-wrap">
        <div>© 2023 Thyself. All rights reserved.</div>
        <Link to="/terms" className="text-blurple">
          Terms of Service
        </Link>
        {session ? (
          <div
            onClick={() => openTally('general', session.user.email)}
            className="text-blurple cursor-pointer"
          >
            Give Us Feedback
          </div>
        ) : (
          <Link to="mailto:team@thyself.ai" className="text-blurple">
            Contact Us
          </Link>
        )}
      </div>
    </div>
  )
}
