import mixpanel from 'mixpanel-browser'

// see vercel.json for the rewrites that make this work
const PROXY_HOST = import.meta.env.DEV ? 'lw-5173.ngrok.dev' : 'app.thyself.ai'

export const analytics = {
  init: () => mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: !!import.meta.env.DEV,
    ignore_dnt: !!import.meta.env.DEV,
    track_pageview: true,
    persistence: 'localStorage',
    api_host: `https://${PROXY_HOST}/api/mxp`,
  }),
  // @ts-expect-error
  track: (...args: any) => mixpanel.track(...args),
  page: (...args: any) => mixpanel.track_pageview(...args),
  identify: (id: string, { email, ...props }: { email?: string }) => {
    mixpanel.identify(id)
    mixpanel.people.set({ $email: email, ...props })
  },
}

// global Tally object is added by script tag in index.html
export const openTally = (form: 'general' | 'afterSession', id?: string) => {
  const key = { afterSession: 'w4BWdb', general: 'wQeld8' }[form]
  const { Tally } = window as any
  Tally?.openPopup(key, {
    layout: 'modal',
    width: 900,
    emoji: {
      text: '💌',
      animation: 'wave',
    },
    autoClose: 2000,
    hiddenFields: { id },
  })
}
