import { IoMdClose } from 'react-icons/io'
import Modal from 'react-modal'
import theme from '../styles/theme'

export default function VideoModal({
  isOpen,
  onClose,
  mode,
}: {
  isOpen: boolean
  onClose: () => void
  mode: 'bare' | 'full'
}) {
  // ideally we would start playing the video in full screen immediately on
  // mobile, but Veed.io doesn't seem to allow that
  if (mode === 'bare')
    return (
      <Modal
        isOpen={isOpen}
        className="fixed inset-0 h-full flex items-center justify-center"
        overlayClassName="fixed inset-0 z-40 bg-black bg-opacity-90 flex items-center justify-center"
        onRequestClose={onClose}
        closeTimeoutMS={200}
      >
        <button
          onClick={onClose}
          className="absolute top-5 right-5 text-white leading-none z-50"
          aria-label="Close"
        >
          <IoMdClose className="w-[32px] h-[32px] ml-[2px] sm:ml-[8px] mr-[-4px]" />
        </button>
        <div className="w-full sm:w-[80vw] h-[0px] pt-[57%] relative">
          <div className="absolute inset-0">
            <iframe
              allow="autoplay; fullscreen"
              className="w-full h-full"
              src="https://www.veed.io/embed/b87a6566-0d5f-4214-8687-24a047375eb0?autoplay=1&muted=1"
              title="Thyself Intro"
            ></iframe>
          </div>
        </div>
      </Modal>
    )

  return (
    <Modal
      isOpen={isOpen}
      className={`${theme.panel.basic} xs:!h-auto gap-4 text-center relative`}
      overlayClassName="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
      onRequestClose={onClose}
      closeTimeoutMS={200}
    >
      <button
        onClick={onClose}
        className="w-8 h-8 rounded-full bg-ltgray flex justify-center items-center absolute right-[20px] top-[20px]"
      >
        <IoMdClose size={24} />
      </button>
      <h1 className={theme.text.pageTitle}>
        Congrats on taking the first step!
      </h1>
      <p className={theme.text.copy}>
        Please watch this tutorial video before starting your session.
      </p>
      <div className="w-full h-[0px] pt-[57%] relative my-8">
        <div className="absolute inset-0 rounded-[16px] overflow-hidden">
          <iframe
            allow="autoplay; fullscreen"
            className="w-full h-full"
            src="https://www.veed.io/embed/b87a6566-0d5f-4214-8687-24a047375eb0?autoplay=1"
            title="Thyself Intro"
          ></iframe>
        </div>
      </div>
      <div className="flex justify-center">
        <button
          onClick={onClose}
          className={`${theme.button.basic} border-none bg-blurple text-white text-lg px-[80px]`}
        >
          Next
        </button>
      </div>
    </Modal>
  )
}
