import { useEffect, useRef } from 'react'
import { useIsDeviceOld } from '../utils/useIsDeviceOld'

const TALK_SPEED = 1
const LISTEN_SPEED = 0.75

export default function Orb({
  isTalking,
  isThinking,
}: {
  isTalking: boolean
  isThinking: boolean
}) {
  const showVideo = !useIsDeviceOld()
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (videoRef.current) {
      if (isTalking) {
        videoRef.current.playbackRate = TALK_SPEED
      } else {
        videoRef.current.playbackRate = LISTEN_SPEED
      }
    }
  }, [isTalking])

  return (
    <div className={`relative w-[30vh] ${showVideo ? 'h-full' : 'h-[30vh] mt-[-30px]'}`}>
      {showVideo ? (
        <video
          ref={videoRef}
          className="min-w-full min-h-full"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src="./images/ai-crop.mp4" type="video/mp4" />
        </video>
      ) : (
        <div
          className="min-w-full min-h-full bg-contain bg-center bg-no-repeat"
          style={{ backgroundImage: 'url(/images/ai-crop-200px-15fps.gif)' }}
        />
      )}
      <div className="h-full w-full absolute top-0 flex items-center">
        {isThinking && <ThinkingDots />}
      </div>
    </div>
  )
}

const ThinkingDots = () => (
  <>
    <div className="absolute loading-dots sm:left-[-45px] left-[-35px] scale-x-[-1]">
      <div className="bg-blurple"></div>
      <div className="bg-blurple"></div>
      <div className="bg-blurple"></div>
    </div>
    <div className="absolute loading-dots sm:right-[-45px] right-[-35px]">
      <div className="bg-blurple"></div>
      <div className="bg-blurple"></div>
      <div className="bg-blurple"></div>
    </div>
  </>
)
