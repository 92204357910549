import { useState, useEffect, CSSProperties } from 'react'
import './styles/sunAnimation.css'
import { SunState } from '../leadin/Sun'

const RISE_TIME_SECONDS = 8
const EXPAND_TIME_SECONDS = 8

const CONTRACT_TIME_SECONDS = 15
const SET_TIME_SECONDS = 15

const FADE_OUT_TIME_SECONDS = 1.5
export default function SunAnimation({ sunState }: { sunState: SunState }) {
  const [risingPosition, setRisingPosition] =
    useState<CSSProperties['bottom']>('100vh')
  const [settingPosition, setSettingPosition] = useState<
    CSSProperties['bottom']
  >('calc(55vh - 42.5vw)')

  const [risingScale, setRisingScale] = useState(1)
  const [settingScale, setSettingScale] = useState(3)

  const [sunOpacity, setSunOpacity] = useState('1')
  const [hideSun, setHideSun] = useState(false)

  const isRising = sunState === 'rise'
  const isSetting = sunState === 'set'

  useEffect(() => {
    // Rising animation
    if (isRising) {
      setRisingPosition('calc(55vh - 42.5vw)')
      setTimeout(() => {
        setRisingScale(3)
      }, 3000)
    }

    // Hide during talk
    if (sunState === 'talk') {
      setSunOpacity('0')

      // Set Z-Index at end of fade
      setTimeout(() => {
        setHideSun(true)
      }, FADE_OUT_TIME_SECONDS * 1000)
    }

    // Setting animation
    if (isSetting) {
      setSunOpacity('1')
      setHideSun(false)

      setSettingScale(1)
      setSettingPosition('100vh')
    }
  }, [sunState])

  const sunBackground: CSSProperties = {
    background: 'linear-gradient(182deg, #6172F3 1.36%, #FFF3B1 175.73%)',
    width: '100vw',
    height: '100vh',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'fixed',
    zIndex: '10',
    opacity: `${sunOpacity}`,
    transition: 'opacity 2s ease-out',
  }

  const baseSunStyle: CSSProperties = {
    position: 'fixed',
    left: '50%',
    width: '85vw',
    height: '85vw',
    borderRadius: '50%',
  }

  const riseStyles: CSSProperties = {
    top: risingPosition,
    transform: `translateX(-50%) scale(${risingScale})`,
    transition: `top ${RISE_TIME_SECONDS}s ease-out, transform ${EXPAND_TIME_SECONDS}s ease-in-out, opacity ${FADE_OUT_TIME_SECONDS}s ease-out`,
  }

  const setStyles: CSSProperties = {
    top: settingPosition,
    transform: `translateX(-50%) scale(${settingScale})`,
    transition: `top ${SET_TIME_SECONDS}s ease-out, transform ${CONTRACT_TIME_SECONDS}s ease-in-out, opacity ${FADE_OUT_TIME_SECONDS}s ease-out`,
  }

  const sunStylesBackground: CSSProperties = {
    background:
      'linear-gradient(180deg, #FF9696 0%, rgba(255, 150, 150, 0.00) 62.32%)',
    boxShadow: '0px 0px 250px 0px #FFAB6F',
    zIndex: '11',
  }

  const sunStylesForeground: CSSProperties = {
    background:
      'linear-gradient(180deg, rgba(255, 243, 177, 0.20) 0%, rgba(255, 243, 177, 0.20) 62.32%)',
    borderRadius: '50%',
    filter: 'blur(15px)',
    zIndex: '12',
  }

  return (
    <div className={`absolute ${hideSun ? 'z-[-1]' : 'z-20'}`}>
      <div style={sunBackground} className={'gradient-bg'}></div>

      {/* Rising Sun */}
      <div
        style={{ ...sunStylesBackground, ...baseSunStyle, ...riseStyles }}
        className={`${isRising ? '' : 'opacity-0'}`}
      ></div>
      <div
        style={{ ...sunStylesForeground, ...baseSunStyle, ...riseStyles }}
        className={`${isRising ? '' : 'opacity-0'}`}
      ></div>

      {/* Setting Sun */}
      <div
        style={{ ...sunStylesBackground, ...baseSunStyle, ...setStyles }}
        className={`${isSetting ? '' : 'opacity-0'}`}
      ></div>
      <div
        style={{ ...sunStylesForeground, ...baseSunStyle, ...setStyles }}
        className={`${isSetting ? '' : 'opacity-0'}`}
      ></div>
    </div>
  )
}
