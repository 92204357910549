import { useEffect, useRef, useState } from 'react'
import theme from '../styles/theme'
import BackButton from './BackButton'

let autoStop: NodeJS.Timeout

export default function SoundCheck({ onBack }: { onBack: () => void }) {
  // vad is added by a script tag in index.html
  const { MicVAD, utils } = (window as any).vad

  const [audioSample, setAudioSample] = useState<any>()
  const [started, setStarted] = useState<any>()
  const [loading, setLoading] = useState<any>()
  const [ended, setEnded] = useState<any>()
  const [error, setError] = useState<'timeout' | 'permission' | undefined>()
  const vad = useRef<typeof MicVAD>()

  const stop = () => {
    clearTimeout(autoStop)
    if (vad.current) console.log('SoundCheck: releasing mic')
    vad.current?.pause()
    vad.current?.stream
      .getAudioTracks()
      .forEach((t: MediaStreamTrack) => t.stop())
    setEnded(true)
  }

  useEffect(() => {
    // release microphone when leaving page
    return () => started && stop()
  }, [started])

  const start = async () => {
    setLoading(true)

    autoStop = setTimeout(() => {
      stop()
      setError('timeout')
    }, 10000)

    try {
      vad.current = await MicVAD.new({
        onSpeechStart: () => {
          clearTimeout(autoStop)
        },
        onSpeechEnd: (audio: any) => {
          const wavBuffer = utils.encodeWAV(audio)
          const base64 = utils.arrayBufferToBase64(wavBuffer)
          const url = `data:audio/wav;base64,${base64}`
          setAudioSample(url)
          stop()
          setError(undefined)
        },
      })
      vad.current.start()

      setAudioSample(undefined)
      setStarted(true)
      setLoading(false)
      setEnded(false)
      setError(undefined)
    } catch (e: any) {
      if (e.message.includes('Permission denied')) {
        setError('permission')
        clearTimeout(autoStop)
      }
    }
  }

  return (
    <div>
      <BackButton onBack={onBack} />
      <div className="text-center">
        <h2 className={theme.text.h2}>Sound Check</h2>
        <h3 className={`${theme.text.h3} !text-blurple`}>Optional</h3>
        <h3 className={`${theme.text.h3} mt-8`}>
          Let's make sure you can hear and be heard by Thyself. Headphones
          recommended&nbsp;🎧
        </h3>
      </div>
      <div className="flex flex-col items-center gap-6 mt-10">
        <div className="flex gap-2">
          <button
            className={`${theme.button.basic} text-lg min-h-[56px] min-w-[186px] rounded-full`}
            onClick={start}
            disabled={started && !ended}
          >
            {ended
              ? 'Retry Test'
              : loading
                ? 'Loading...'
                : started
                  ? 'Listening...'
                  : 'Start Test'}
          </button>
        </div>

        {started && !ended && (
          <div>
            Say a short sentence out loud, such as "I am testing the
            microphone."
          </div>
        )}
        {audioSample && (
          <div className="flex flex-col items-center gap-3">
            <audio src={audioSample} controls />
            <div>
              Press the play button. If you can hear what you just said,
              everything is working.
            </div>
            <div>
              If you don't hear anything, check your microphone and speakers,
              and tap Retry Test.
            </div>
          </div>
        )}
        {error && (
          <div className="text-red-600">
            {error === 'permission' &&
              'You need to give permission for this app to use the microphone.'}
            {error === 'timeout' && "We didn't hear anything after 10 seconds."}
          </div>
        )}
      </div>
    </div>
  )
}
