import { CSSProperties, useEffect, useRef, useState } from 'react'
import { supabase } from '../supabase'
import { formatDuration, formatISO8601Date } from '../utils/time'
import { useNavigate, useParams } from 'react-router-dom'
import theme from '../styles/theme'
import BackButton from '../leadin/BackButton'
import { FaArrowRight, FaTrashAlt } from 'react-icons/fa'
import { ArrowSmallLeftIcon } from '@heroicons/react/20/solid'
import type { JournalEntry } from './data'
import { groupMessages } from './data'
import { captureException } from '../utils/sentry'
import ConfirmModal from '../components/ConfirmModal'

export default function () {
  const [entry, setEntry] = useState<JournalEntry>()
  const [isDeleting, setIsDeleting] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const navigate = useNavigate()
  const params = useParams()
  const id = params.id

  useEffect(() => {
    const fetchEntryDetails = async () => {
      try {
        const getMeta = async () => {
          const { data: convo, error } = await supabase
            .from('conversations')
            .select('*, summaries(*)')
            .eq('id', id)
            .maybeSingle()
          if (error || !convo) {
            throw error || new Error('No convo')
          }

          const summary = convo.summaries[0]
          return {
            title: summary?.title || 'Untitled',
            created_at: new Date(convo.created_at),
            duration: convo.metadata?.duration,
            summary: summary?.user_facing_text,
            mode: convo.prompt,
          }
        }

        const getMessages = async () => {
          const { data, error } = await supabase.rpc('conversation_details', {
            convo_id: id,
          })
          if (error || !data) {
            throw error || new Error('No data')
          }

          return groupMessages(
            data.map((d: any) => ({
              ...d,
              created_at: new Date(d.created_at),
            }))
          )
        }

        const [meta, messages] = await Promise.all([getMeta(), getMessages()])
        const entry: JournalEntry = { ...meta, messages }
        setEntry(entry)
      } catch (error) {
        // TODO: handle error
        console.error(error)
      }
    }

    fetchEntryDetails()
  }, [])

  const handleStartSession = () => {
    navigate(`/talk?mode=${entry!.mode}`)
  }

  const handleDeleteEntry = async () => {
    setIsDeleting(true)
    const resp = await supabase
      .from('conversations')
      .delete({ count: 'exact' })
      .eq('id', id)

    if (resp.error) {
      alert('An error occurred. Please try again in a bit.')
      captureException(resp.error)
    } else {
      setTimeout(() => {
        setShowDeleteModal(false)
        setIsDeleting(false)
        navigate('/journal')
      }, 1000)
    }
  }

  if (!entry) {
    return (
      <div className="text-center text-white text-lg mt-[10vh]">Loading...</div>
    )
  }

  return (
    <>
      <div
        className={`${theme.panel.basic} text-center min-h-[calc(100vh-146px)] max-xs:p-0 max-xs:overflow-hidden max-xs:bg-white max-xs:grow-0 xs:grow md:w-full`}
      >
        <BackButton onBack={() => navigate('/journal')} />
        <div className="w-[90%] max-w-[900px] mx-auto font-inter text-[16px] xs:w-full mt-8 xl:mt-0 relative pb-[104px] xs:pb-[82px]">
          <div className="text-left">
            <h2 className={`${theme.text.h2} text-[20px] xs:text-2xl`}>
              {entry.title}
            </h2>
            <div className="flex justify-between m4-1 xs:mt-4">
              <p className="text-textgray text-[16px] xs:text-textblack xs:text-[20px]">
                {formatISO8601Date(entry.created_at)}
              </p>
              <p className="text-textgray text-[16px] xs:text-[20px]">
                {formatDuration(entry.duration)}
              </p>
            </div>
            <hr className="mt-4 xs:hidden" />
            <p className="text-textgray mt-4 xs:mt-10">{entry.summary}</p>
            <p className="text-textblack text-[20px] mt-10">Transcript</p>
            <div className="bg-ltgray mt-6 px-8 py-4 flex flex-col rounded-3xl xs:bg-white xs:border-[#D0D5DD] xs:border-[1px]">
              {entry.messages.map((message, index) => (
                <div
                  key={index}
                  className={`flex my-4 text-[16px] w-[80%] xs:w-[62%] ${
                    !message.human
                      ? 'justify-start text-textgray'
                      : 'ml-[20%] justify-end text-blurple xs:ml-[38%]'
                  }`}
                  data-timestamp={message.created_at.toISOString()}
                >
                  {message.content}
                </div>
              ))}
            </div>
          </div>
          <StickyBottomBar>
            <ArrowSmallLeftIcon
              onClick={() => navigate('/journal')}
              className="h-[50px] w-[50px] cursor-pointer text-textblack rounded-full border-[1px] xs:hidden"
            />
            <button
              onClick={handleStartSession}
              className="flex justify-between items-center text-textblack px-2 py-6 border-[#d0d5dd] border-[1px] rounded-full text-[14px] xs:text-[18px] xs:px-4 h-[50px]"
            >
              Start New Session
              <FaArrowRight className="bg-blurple ml-2 rounded-full text-white text-sm p-[6px] w-[24px] h-[24px]" />
            </button>
            <button
              onClick={() => setShowDeleteModal(true)}
              className="bg-[#FEE4E2] rounded-full flex justify-center items-center text-[#D92D20] text-sm w-[50px] h-[50px]"
            >
              <FaTrashAlt className="text-xl" />
            </button>
          </StickyBottomBar>
        </div>
      </div>
      <ConfirmModal
        variant='alert'
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDeleteEntry}
        title="Delete Session"
        message="Are you sure you want to delete this session? This is permanent and cannot be undone."
        cancelLabel="Cancel"
        disabled={isDeleting}
        confirmLabel={isDeleting ? 'Deleting...' : 'Delete'}
      />
    </>
  )
}

export const bottomBarStyles = {
  fixed: {
    bottom: '-10px',
    background:
      'linear-gradient(to bottom, transparent, 5%, white, 70%, white)',
    position: 'fixed',
  } as CSSProperties,
  static: {
    position: 'absolute',
    background: 'transparent',
  } as CSSProperties,
}

export const StickyBottomBar = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [bottomBarWidth, setBottomBarWidth] = useState('100%')
  const [bottomBarStyle, setBottomBarStyle] = useState(bottomBarStyles.static)
  const ref = useRef<HTMLDivElement>(null)

  // Used to dynamically set width of bottom toolbar
  useEffect(() => {
    if (ref.current) {
      const handleResize = () => {
        const parent = ref.current?.parentNode as HTMLElement | null
        if (parent) {
          const width = parent.clientWidth + 'px'
          setBottomBarWidth(width)
        }
      }

      handleResize()
      window.addEventListener('resize', handleResize)
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [ref.current])

  const checkBottomBarPosition = () => {
    const scrollPosition = window.scrollY + window.innerHeight
    const documentHeight = document.documentElement.scrollHeight

    // code smell: fudge factor
    if (documentHeight - scrollPosition <= 40) {
      setBottomBarStyle(bottomBarStyles.static)
    } else {
      setBottomBarStyle(bottomBarStyles.fixed)
    }
  }

  // Used to set bottom position of bottom bar to not overflow parent container
  useEffect(() => {
    window.addEventListener('scroll', checkBottomBarPosition)
    window.addEventListener('resize', checkBottomBarPosition) // To handle window resizing
    checkBottomBarPosition() // Initial check

    return () => {
      window.removeEventListener('scroll', checkBottomBarPosition)
      window.removeEventListener('resize', checkBottomBarPosition)
    }
  })

  return (
    <div
      ref={ref}
      style={{ ...bottomBarStyle, width: bottomBarWidth }}
      className="py-8 justify-between rounded-xl bg-white w-full flex max-w-[900px]"
    >
      {children}
    </div>
  )
}
