export function formatISO8601Date(date: Date): string {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const day = date.getDate()
  const month = months[date.getMonth()]
  const year = date.getFullYear()

  let suffix = 'th'
  const exceptions = [1, 21, 31]
  if (exceptions.includes(day % 10)) {
    suffix = 'st'
  } else if (day % 10 === 2 && day !== 12) {
    suffix = 'nd'
  } else if (day % 10 === 3 && day !== 13) {
    suffix = 'rd'
  }

  return `${month} ${day}${suffix}, ${year}`
}

export function formatDuration(seconds: number, short = false) {
  const hours = seconds > 3600 ? Math.floor(seconds / 3600) : 0
  const minutes = Math.round((seconds - hours * 3600) / 60)

  const h = short ? 'h' : ' hour'
  const m = short ? 'm' : ' minute'
  const space = short ? '' : ' '
  if (hours > 0)
    return (
      `${hours}${space}${h}${!short && hours > 1 ? 's' : ''}, ` +
      `${minutes}${space}${m}${!short && minutes > 1 ? 's' : ''}`
    )
  return `${minutes}${space}${m}${!short && minutes > 1 ? 's' : ''}`
}
