import debounce from 'lodash/debounce'
import { useEffect, useRef } from 'react'
import theme from '../styles/theme'

export default function useSetRoomHeight() {
  const panel = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (window.innerWidth > theme.breakpoint.xs) return

    const resize = debounce(
      () => {
        if (!panel.current) return
        const height = window.innerHeight

        // the panel's top margin is set to 1rem with the `mt-4` Tailwind class,
        // so we subtract twice that to have the same bottom margin
        panel.current.style.height = `calc(${height}px - 2rem)`
        window.scrollTo(0, 0)
        document.body.style.overflow = 'hidden'
      },
      100,
      { leading: true }
    )

    resize()
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
      document.body.style.overflow = 'auto'
    }
  }, [panel.current])

  return panel
}
