import Modal from 'react-modal'
import { IoMdClose } from 'react-icons/io'

import theme from '../styles/theme'

export default function TipsModal({
  isOpen,
  onClose,
  showCaptions,
  setShowCaptions,
}: {
  isOpen: boolean
  onClose: () => void
  showCaptions: boolean
  setShowCaptions: (val: boolean) => void
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={200}
      overlayClassName="z-20 fixed top-0 bg-black bg-opacity-50 pt-5 overflow-y-auto h-full w-full flex sm:items-center sm:justify-center sm:pb-5"
      className="relative mt-[20vh] min-h-[calc(80vh-20px)] bg-white rounded-t-[40px] w-full p-6 pt-[40px] text-textgray self-start sm:max-w-[600px] sm:m-auto sm:rounded-b-[40px]"
    >
      <div
        onClick={onClose}
        className="absolute right-[20px] top-[20px] rounded-full bg-ltgray p-1"
      >
        <IoMdClose className="h-[20px] w-[20px]" />
      </div>
      <div className={`mb-5 ${theme.text.h2}`}>Help and Tips</div>
      <div className="font-satoshi text-xl text-textblack border-b-2 pb-5 border-ltgray flex justify-between items-center">
        Show captions
        <input
          type="checkbox"
          checked={showCaptions}
          onChange={() => setShowCaptions(!showCaptions)}
          className="w-[22px] h-[22px]"
        />
      </div>

      <h3 className={`${theme.text.sectionHeader} text-[20px] mt-5`}>
        General
      </h3>
      <p>
        Wear headphones.
        <br />
        Speak clearly and continuously.
        <br />
        Tap the Send &#x21E7; button when you're done speaking. You can also press the spacebar or say "Done" or "Continue".
      </p>

      <h3 className={`${theme.text.sectionHeader} text-[20px] mt-5`}>
        Ask For Things
      </h3>
      <p>
        Ask for more clarity. Ask for Thyself to rephrase or repeat. Ask for
        more or less guidance.
      </p>

      <h3 className={`${theme.text.sectionHeader} text-[20px] mt-5`}>
        Be Patient
      </h3>
      <p>
        Be gentle with yourself. Go slow and let the process unfold. Commit to
        staying with it, even if it may feel difficult. It's worth it!
      </p>

      <h3 className={`${theme.text.sectionHeader} text-[20px] mt-5`}>
        Contact Us
      </h3>
      <p>
        Contact us at{' '}
        <a className="text-blurple" href="mailto:team@thyself.ai">
          team@thyself.ai
        </a>{' '}
        for any questions or feedback!
      </p>
    </Modal>
  )
}
