import { Link, useNavigate, useLocation } from 'react-router-dom'
import NavMenu from './NavMenu'
import { useGrantMaybe } from '../session'
import Logo from '../images/logo'
import { FaHome, FaBookOpen, FaInfoCircle } from 'react-icons/fa'

export default function ({ mobileAltTheme }: { mobileAltTheme?: boolean }) {
  const grant = useGrantMaybe()
  const minutes_used = grant?.minutes_used || 0
  const minutes = grant?.minutes || 0
  const unlimited = minutes === -1
  const navigate = useNavigate()
  const location = useLocation()
  const isHome = location.pathname === '/'
  const isJournal = location.pathname.includes('/journal')
  const showLeftMenu = isHome || isJournal

  // Hide left menu items on the conversation/lead-in pages
  const leftMenuItemClasses = `${
    showLeftMenu ? '' : 'hidden'
  } font-satoshi-regular bg-ltgray h-[46px] leading-[46px] text-[18px] transition-all duration-200 flex items-center justify-center rounded-full px-4 mt-4 xs:mt-0 xs:font-inter xs:text-[14px] xs:h-[50px] xs:rounded-[10px] xs:hover:bg-[#fff]`

  return (
    <div
      className={`text-center grid grid-cols-2 xs:grid-cols-3 relative p-[20px] xs:p-0 ${
        mobileAltTheme ? 'bg-transparent' : 'bg-white'
      } xs:bg-transparent z-30`}
    >
      {/* Home + Journal + Docs Buttons */}
      <div className="order-3 xs:order-1 flex items-center">
        <button
          className={`${leftMenuItemClasses} ${
            isHome ? '!bg-[#D0D5DD] xs:!bg-white' : 'xs:bg-[#fff]/60'
          } mr-2 xs:mr-4`}
          onClick={() => navigate('/')}
        >
          <FaHome className="mr-2 sm:mr-0 lg:mr-2 text-xl" />
          <span className="sm:hidden lg:block">Home</span>
        </button>

        <button
          className={`${leftMenuItemClasses} ${
            isJournal ? '!bg-[#D0D5DD] xs:!bg-white' : 'xs:bg-[#fff]/60'
          } mr-2 xs:mr-4`}
          onClick={() => navigate('/journal')}
        >
          <FaBookOpen className="mr-2 sm:mr-0 lg:mr-2 text-xl" />
          <span className="sm:hidden lg:block">Journal</span>
        </button>

        <button
          className={`${leftMenuItemClasses} ${
            isJournal ? '!bg-[#D0D5DD] xs:!bg-white' : 'xs:bg-[#fff]/60'
          }`}
          onClick={() =>
            window.open(
              'https://thyself.notion.site/Thyself-Docs-22c14cbb0b534dca90279fda956065fa',
              '_blank'
            )
          }
        >
          <FaInfoCircle className="mr-2 sm:mr-0 lg:mr-2 text-xl" />
          <span className="sm:hidden lg:block">Docs</span>
        </button>
      </div>

      {/* Main Logo */}
      <div className="order-1 xs:order-2 flex-1 flex xs:justify-center items-center gap-2 relative">
        <Link
          to="/"
          className={`relative h-[44px] xs:h-[50px] top-[3px] ${
            mobileAltTheme ? 'text-white' : 'text-blurple'
          } xs:text-white`}
        >
          <Logo />
        </Link>
      </div>

      {/* Nav Menu */}
      <div className="order-2 xs:order-3 flex items-center justify-self-end gap-[16px]">
        {grant && !unlimited && (
          <Link
            to="/account"
            className="bg-[#fff6] px-[10px] py-[2px] h-[26px] leading-[22px] rounded-[24px] cursor-pointer invisible sm:visible"
          >
            {unlimited ? (
              <div className="text-[24px]">&infin;</div> // infinity symbol
            ) : (
              `${minutes - minutes_used} min`
            )}
          </Link>
        )}
        <NavMenu minutes={minutes - minutes_used} unlimited={unlimited} />
      </div>
    </div>
  )
}
