import { useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import SupabaseAuth from './SupabaseAuth'
import { supabase } from './supabase'
import { analytics } from './analytics'
import theme from './styles/theme'
import { BUY_URL } from './pages/Account'
export type AuthState = 'loading' | 'done'

import type { GoTrueSession } from './session'
import {
  useSessionMaybe,
  useSetSession,
  makeWrappedSession,
  useRedirectAfterLogin,
  useShouldCountSignIn,
  useGrantMaybe,
  useSetGrant,
} from './session'
import { AuthChangeEvent } from '@supabase/gotrue-js'
import MainLayout from './layout/MainLayout'

const PREVENT_NO_CODE_ACCESS = true
const TEST_NO_ACCESS = false

type Props = {
  children?: any
  required?: boolean
  standalone?: boolean
  useLayout?: boolean
}

function Auth({ children, required, standalone, useLayout = true }: Props) {
  const setSession = useSetSession()
  const session = useSessionMaybe()
  const grant = useGrantMaybe()
  const setGrant = useSetGrant()
  const [authState, setAuthState] = useState<AuthState>('loading')
  const [error, setError] = useState<string>()
  const [code, setCode] = useState('')
  const [params] = useSearchParams()
  const [redirectAfterLogin, setRedirectAfterLogin] = useRedirectAfterLogin()
  const [shouldCountSignIn, setShouldCountSignIn] = useShouldCountSignIn()
  const [initialGetSessionDone, setInitialGetSessionDone] = useState(false)
  const navigate = useNavigate()

  // always look for a code in the URL, regardless of login status; if one is
  // found, store it so it can be used later, even from a different browser tab
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    if (code) localStorage.setItem('accessCode', code)
  }, [])

  // see below for the explanation of why a ref is used here
  const stateChangeCallback = useRef<
    (event: AuthChangeEvent, sess: GoTrueSession | null) => void
  >(() => {})

  const setWrappedSession = async (newSession: GoTrueSession | null) => {
    if (!newSession) return setSession(undefined)
    setSession(await makeWrappedSession(newSession))
  }

  useEffect(() => {
    // restore existing session on page load
    supabase.auth.getSession().then(async ({ data: { session } }) => {
      await setWrappedSession(session)
      if (!session) setAuthState('done')
      setInitialGetSessionDone(true)
      if (session) {
        identifyUser(session)
        processLogin(session, false, 'getSession')
      }
    })
  }, [])

  useEffect(() => {
    if (!initialGetSessionDone) return

    // update page after logging in or out
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(
      // this has to be an anonymous function that looks up the ref when it
      // runs, not a direct reference to `stateChangeCallback.current`, so that
      // it always uses the most up-to-date value
      (...args) => stateChangeCallback.current(...args)
    )

    return () => subscription.unsubscribe()
  }, [initialGetSessionDone])

  useEffect(() => {
    // this callback is defined in a useRef so that we can call
    // `onAuthStateChange` only once, but update the listener implementation as
    // the session changes
    stateChangeCallback.current = async (
      event: AuthChangeEvent,
      newSession: GoTrueSession | null
    ) => {
      // onAuthStateChange fires each time the user returns to the browser tab.
      // we want to avoid re-rendering unless the session has actually changed
      const isSameSession =
        session && newSession && session.expires_at == newSession.expires_at

      // don't count reloading an existing session as a sign-in. see session.ts
      // for more explanation
      const isReloadedSession = event === 'SIGNED_IN' && !shouldCountSignIn

      // console.log({
      //   event,
      //   newSession,
      //   standalone,
      //   redirectAfterLogin,
      //   isSameSession,
      //   isReloadedSession,
      // })

      if (!isSameSession && session) {
        identifyUser(session)
      }

      if (isSameSession || isReloadedSession) return

      await setWrappedSession(newSession)

      if (event === 'SIGNED_IN' && newSession) {
        setShouldCountSignIn(false)

        if (!standalone && redirectAfterLogin) {
          navigate(redirectAfterLogin)
          setRedirectAfterLogin(undefined)
        }

        processLogin(newSession, true, 'callback')
      }
    }

    // once we have a session, look up an access grant.
    if (!session || grant) return

    ;(async () => {
      setAuthState('loading')
      const { grant: newGrant, error } = await checkGrants()
      if (!grant && newGrant) setGrant(newGrant)
      setError(error)
      setAuthState('done')
    })()
  }, [session, shouldCountSignIn, redirectAfterLogin, standalone])

  const applyCode = () => {
    ;(window as any).location = `/?code=${code}`
  }

  useEffect(() => {
    if (!session && required && authState === 'done') {
      setShouldCountSignIn(true)
    }
  }, [session, required, authState])

  if (authState !== 'done')
    return (
      <div
        className="h-[100vh] w-[100vw] fixed bg-fixed bg-top bg-cover"
        style={{ backgroundImage: `url("/images/background.jpg")` }}
      >
        <div className="text-center text-white text-lg mt-[10vh]">
          Loading...
        </div>
      </div>
    )

  if (!session && required) {
    return (
      <MainLayout>
        <div
          className="bg-[#fff] xs:rounded-[30px] max-xs:pb-10 xs:py-6 md:py-[50px] px-2 md:px-6 xs:mx-auto md:w-[533px] xs:w-[95%] xs:mb-5 md:mb-[40px] xs:mt-4"
          style={
            window.innerWidth <= theme.breakpoint.xs
              ? { minHeight: window.innerHeight - 84 }
              : {}
          }
        >
          <SupabaseAuth
            view={params.get('show') == 'signin' ? 'sign_in' : 'sign_up'}
            supabaseClient={supabase}
            providers={['google']}
            magicLink
          />
        </div>
      </MainLayout>
    )
  }

  const showCodeInputForm =
    PREVENT_NO_CODE_ACCESS &&
    session &&
    !grant &&
    authState === 'done' &&
    required

  if (showCodeInputForm) {
    return (
      <MainLayout>
        <NoAccess
          {...{ error, setCode, applyCode, email: session.user.email }}
        />
      </MainLayout>
    )
  }

  if (standalone) {
    return <Redirect to={params.get('next') || '/'} />
  }

  return useLayout ? <MainLayout>{children}</MainLayout> : children
}

export default Auth

function Redirect({ to }: { to: string }) {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(to)
  }, [])
  return null
}

function NoAccess({
  error,
  setCode,
  applyCode,
  email,
}: {
  error?: string
  setCode: (v: string) => void
  applyCode: () => void
  email?: string
}) {
  return (
    <div className="text-left max-w-[480px] mx-auto mt-14 flex flex-col gap-3 bg-white rounded-[16px] p-8">
      <div>
        You've used up your free trial time with Thyself! Please subscribe to
        continue.
      </div>
      <a
        className={`${theme.button.fancy} mb-8`}
        href={`${BUY_URL}?prefilled_email=${email}`}
      >
        Purchase a subscription
      </a>
      <span className="text-left">
        Or, if you have an access code, enter it here:
      </span>
      <div className="flex gap-2">
        <div className="flex-1 border border-gray-300 py-1 px-2 rounded-[8px]">
          <input
            className="w-[100%] outline-none"
            type="text"
            placeholder="Enter access code"
            onChange={e => setCode(e.target.value)}
          />{' '}
        </div>
        <button
          className="justify-self-center border border-gray-300 py-1 px-3 rounded-[8px]"
          onClick={applyCode}
        >
          Submit
        </button>
      </div>
      <div className="text-red-600">{error}</div>

      <p className="text-left">
        If you need help, please{' '}
        <a
          className="text-blurple font-semibold"
          href="mailto:support@thyself.ai"
        >
          contact us
        </a>
        , and we'll sort things out right away.
      </p>
    </div>
  )
}

async function checkGrants() {
  const result: any = {}

  if (TEST_NO_ACCESS) return { error: 'TEST_NO_ACCESS is on' }

  // 1. see if there is a code in the URL; if so, attempt to claim an access
  //    grant with it

  const params = new URLSearchParams(window.location.search)
  const code = params.get('code') || localStorage.getItem('accessCode')
  if (code) {
    // if there is a code, and it turns out to be invalid, don't look for valid
    // grants later. now, this could get in the way of a user who has a valid
    // grant already but loaded a URL with a bad code for some reason... but the
    // fix for that is just to visit the URL without any code.
    let skipLookup = false

    const { error, data } = await supabase.rpc('claim_access_grant', { code })
    console.log(`is code ok? ${data}`)

    if ((data as string).startsWith('ok!')) {
      window.history.replaceState(null, '', window.location.pathname)
    } else if ((data as string).startsWith('no!')) {
      result.error = 'The access code provided is not valid.'
      skipLookup = true
    } else if (error) {
      result.error = error.message
      skipLookup = true
    }

    delete localStorage.accessCode

    if (skipLookup) return result
  }

  // 2. look for valid grants for the current user, including one that was just
  //    claimed

  let { data: grant } = await supabase
    .rpc('lookup_access_grant')
    .select('id, minutes_used, minutes')
    .maybeSingle()

  if (grant) {
    result.grant = grant
  }

  return result
}

const identifyUser = (session: GoTrueSession) => {
  const { id, email } = session.user
  Sentry.setUser({ id, email })
  analytics.identify(id, { email })
  console.log('Identified user')
}

const processLogin = (
  session: GoTrueSession,
  confirmedNewSession: boolean,
  tag: string
) => {
  const {
    user: { id, email, created_at },
    expires_in,
    expires_at,
  } = session

  if (!confirmedNewSession) {
    const now = Date.now() / 1000
    const sessionCreatedAt = expires_at! - expires_in
    // count a login if the session was created within the last 10 seconds. yes,
    // this will double-count if the user reloads the page once or more in that
    // time.
    if (now - sessionCreatedAt > 10) return
  }

  analytics.identify(id, { email })
  analytics.track('User Logged In')
  console.log(`Logged in (${tag})`)

  // if created_at is close to the current time, count this as a signup
  //
  // FIXME this needs to move to the auth page if we turn on email
  // confirmations
  const createdAt = new Date(created_at).getTime()
  const now = new Date().getTime()
  if (Math.abs(now - createdAt) < 10_000) {
    analytics.track('User Signed Up')

    // @ts-expect-error: FB conversion tracking, see index.html
    if (window.fbq) window.fbq('track', 'CompleteRegistration')
  }
}
