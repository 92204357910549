import Modal from 'react-modal'
import theme from '../styles/theme'
import { BUY_URL } from '../pages/Account'
import { useSession } from '../session'

const SubscribeOverlay = ({
  isOpen,
  onClose,
  onConfirm,
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}) => {
  const session = useSession()
  const email = session.user?.email
  return (
    <Modal
      isOpen={isOpen}
      className="outline-none"
      onRequestClose={onClose}
      overlayClassName="z-40 fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center"
    >
      <div className={theme.panel.overlay}>
        <div className="flex justify-end">
          <img
            src="./images/close.svg"
            alt="Close"
            onClick={onClose}
            className="self-end"
          />
        </div>
        <div className="flex flex-col justify-center items-center gap-8">
          <div className="text-center mt-4">
            <h2 className={theme.text.h2}>Time To Upgrade</h2>
            <p className="text-[16px] text-[#7d7d7d] mt-[20px]">
              You have used up your free trial time. Great work!
            </p>
            <p className="text-[16px] text-[#7d7d7d] mt-[20px]">
              You can continue your practice by purchasing a subscription.
            </p>
          </div>
          <div className="text-[25px] font-satoshi font-[500] leading-[36px] mt-[20px] text-center">
            $29/month
            <br />
            Unlimited Session Time
          </div>
          <a
            className={`${theme.button.decision} text-lg h-[44px] w-[300px] self-center mt-[2rem]`}
            onClick={onConfirm}
            href={`${BUY_URL}?prefilled_email=${email}`}
            target="_blank"
          >
            Subscribe
          </a>
        </div>
      </div>
    </Modal>
  )
}

export default SubscribeOverlay
