import { marked } from 'marked'
import privacyMd from '../docs/privacy.md?raw'
import theme from '../styles/theme'

const text = marked.parse(privacyMd)

export default function Privacy() {
  return (
    <div className={`${theme.panel.basic} prose font-inter p-12`}>
      <h1 className={theme.text.pageTitle}>Privacy Policy</h1>
      <div dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  )
}
