import { Menu, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { analytics } from '../analytics'
import { useSessionMaybe, useSetSession } from '../session'
import type { Session } from '../session'
import { supabase } from '../supabase'

const menuItemStyle =
  'whitespace-nowrap border-t border-ltgray text-[14px] text-[#344054] font-medium group flex w-full items-center px-4 py-2'

export default function NavMenu({
  minutes,
  unlimited,
}: {
  minutes: number
  unlimited: boolean
}) {
  const session = useSessionMaybe()
  const location = useLocation()

  if (!session)
    return (
      location.pathname != '/auth' && (
        <Link
          to={`/auth?show=signin&next=${location.pathname}`}
          className="font-satoshi-regular bg-ltgray text-[18px] rounded-full px-4 h-[40px] flex items-center"
        >
          Sign in
        </Link>
      )
    )

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="rounded-[200px] w-[40px] h-[40px] md:w-[50px] md:h-[50px] flex items-center justify-center bg-ltgray sm:bg-[#fff]/60 transition-all duration-200">
          <img src="/icons/user.svg" alt="User" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 mt-2 min-w-[120px] origin-top-right divide-y divide-gray-100 rounded-[8px] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
          {session ? (
            <LoggedInItems {...{ session, unlimited, minutes }} />
          ) : (
            <LoggedOutItems />
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const LoggedOutItems = () => {
  const location = useLocation()

  return (
    <>
      {location.pathname !== '/' && (
        <Menu.Item>
          <Link to="/" className={menuItemStyle}>
            Home
          </Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link
          to={`/auth?next=${location.pathname}`}
          className={`${menuItemStyle} border-t-0`}
        >
          Sign up / Sign in
        </Link>
      </Menu.Item>
    </>
  )
}

const LoggedInItems = ({
  session,
  unlimited,
  minutes,
}: {
  session: Session
  unlimited: boolean
  minutes: number
}) => {
  const navigate = useNavigate()
  const setSession = useSetSession()

  const [developerMode, setDeveloperMode] = useState<boolean>(
    !!localStorage.developer
  )

  const toggleDeveloperMode = () => {
    if (!developerMode) {
      localStorage.developer = 1
    } else {
      delete localStorage.developer
    }
    setDeveloperMode(!developerMode)
    window.location.reload()
  }

  const [enableVAD, setEnableVAD] = useState<boolean>(!!localStorage.enableVAD)

  const toggleEnableVAD = () => {
    if (!enableVAD) {
      localStorage.enableVAD = 1
    } else {
      delete localStorage.enableVAD
    }
    setEnableVAD(!enableVAD)
  }

  const signout = () => {
    if (session) {
      analytics.track('User Logged Out')
    }

    supabase.auth.signOut().then(() => {
      setSession(undefined)
    })

    navigate('/')
  }

  let truncatedEmail = session.user.email
  if ((truncatedEmail?.length ?? 0) > 40) {
    const matches = truncatedEmail!.match(/^(.*)@(.*)$/)!
    truncatedEmail = `${matches[1].substring(0, 20)}...@${matches[2]}`
  }

  return (
    <>
      <Menu.Item>
        <button className={`${menuItemStyle} font-normal border-t-0`}>
          {session.guest ? 'Guest' : truncatedEmail}
        </button>
      </Menu.Item>
      {!unlimited && (
        <Menu.Item>
          <span className={`${menuItemStyle} font-normal border-t-0 sm:hidden`}>
            {minutes} minutes remaining
          </span>
        </Menu.Item>
      )}
      {location.pathname !== '/' && (
        <Menu.Item>
          <Link to="/" className={menuItemStyle}>
            Home
          </Link>
        </Menu.Item>
      )}
      {(session.admin || localStorage.developer) && (
        <>
          {location.pathname !== '/admin' && session.admin && (
            <Menu.Item>
              <Link to="/admin" className={menuItemStyle}>
                Admin
              </Link>
            </Menu.Item>
          )}
          <Menu.Item>
            <label className={menuItemStyle}>
              Developer mode
              <input
                type="checkbox"
                className="ml-[0.5em] relative top-[1px]"
                checked={developerMode}
                onChange={toggleDeveloperMode}
              />
            </label>
          </Menu.Item>
          <Menu.Item>
            <label className={menuItemStyle}>
              Enable VAD
              <input
                type="checkbox"
                className="ml-[0.5em] relative top-[1px]"
                checked={enableVAD}
                onChange={toggleEnableVAD}
              />
            </label>
          </Menu.Item>
        </>
      )}
      {!session.guest && (
        <Menu.Item>
          <Link to="/account" className={menuItemStyle}>
            Account
          </Link>
        </Menu.Item>
      )}
      <Menu.Item>
        <button onClick={signout} className={`${menuItemStyle} rounded-b-md`}>
          Sign out
        </button>
      </Menu.Item>
    </>
  )
}
