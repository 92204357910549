type BareMode = {
  label: string
  shortLabel?: string
  prompt: string
}

type Mode = BareMode & {
  sub_label?: string
  description: string
  img?: string
  hidden?: boolean
}

export const modeLabel = (name: string, short = false) => {
  if (name === mainSession.prompt) return mainSession.label
  let mode = useCases.find(m => m.prompt === name)
  if (!mode) mode = modes.find(m => m.prompt === name)
  return (short ? mode?.shortLabel : mode?.label) || name
}

export const mainSession: BareMode = {
  label: 'Open Session',
  prompt: 'open',
  shortLabel: 'Open',
}

export const useCases: Mode[] = [
  {
    label: 'Resolve Stuck Feelings',
    shortLabel: 'Stuck Feelings',
    description: 'Find fresh energy and perspective around an emotional block.',
    prompt: 'stuck',
    img: '/images/focus/stuck.jpg',
  },
  {
    label: 'Make a Decision',
    shortLabel: 'Decision',
    description: 'Find clarity about a decision by consulting your felt sense.',
    prompt: 'decide',
    img: '/images/focus/decide.jpg',
  },
  {
    label: 'Work With a Trigger',
    shortLabel: 'Trigger',
    description:
      'Find relief from something that is causing a strong reaction.',
    prompt: 'trigger',
    img: '/images/focus/trigger.jpg',
  },
  {
    label: 'Prepare for a Conversation',
    shortLabel: 'Conversation Prep',
    description:
      'Talk through thoughts and feelings about an upcoming conversation.',
    prompt: 'rehearse',
    img: '/images/focus/rehearse.jpg',
  },
  {
    label: 'Explore Thoughts and Beliefs',
    shortLabel: 'Thoughts',
    description: 'Make peace with the voice in your head.',
    prompt: 'thoughts',
    img: '/images/focus/thoughts.jpg',
  },
  {
    label: 'Practice Self-Love',
    shortLabel: 'Self-Love',
    description:
      'Take some time to cultivate love and compassion for yourself.',
    prompt: 'love',
    img: '/images/focus/love.jpg',
  },
  {
    label: 'Decompress',
    shortLabel: 'Decompress',
    description:
      'Talk it out, rant, or just chill out after a long day or stressful situation.',
    prompt: 'decompress',
    img: '/images/focus/decompress.jpg',
  },
]

export const modes: Mode[] = [
  {
    label: 'Belief Work',
    sub_label: '~ 10 - 30 minutes',
    description: 'Find freedom through exploring the nature of your beliefs.',
    prompt: 'belief_work',
    img: '/images/modalities/belief-work.jpg',
  },
  {
    label: 'Inquiry',
    sub_label: '~ 10 - ∞ minutes',
    description:
      'Dive deep into your experience and uncover the roots of patterns in your life with thought-provoking questions.',
    prompt: 'inquiry',
    img: '/images/modalities/inquiry.jpg',
  },
  {
    label: 'Parts Work',
    sub_label: '~ 20 - 40 minutes',
    description:
      'Resolve internal conflict by acknowledging and appreciating all your internal parts.',
    prompt: 'ifs',
    img: '/images/modalities/ifs.jpg',
  },
  {
    label: 'Ideal Parents',
    sub_label: '~ 20 - 40 minutes',
    description:
      'Spend quality time with "Ideal Parents" to nurture your inner child and heal attachment issues.',
    prompt: 'ipf',
    img: '/images/modalities/ipf.jpg',
  },
  {
    label: 'Somatic Inquiry',
    sub_label: '~ 20 - 40 minutes',
    description:
      'Allow answers to your deep questions to emerge from attuning to your felt sense.',
    prompt: 'somatic',
    img: '/images/modalities/somatic.jpg',
  },
  {
    label: 'Space Holding',
    sub_label: '∞ minutes',
    description:
      'Freely express yourself with minimal interruptions and no judgments.',
    prompt: 'space_holding',
    img: '/images/modalities/space-holding.jpg',
  },
]

export const devModes: BareMode[] = [
  { label: 'Test A', prompt: 'test_a' },
  { label: 'Test B', prompt: 'test_b' },
  { label: 'Test C', prompt: 'test_c' },
  { label: 'Test D', prompt: 'test_d' },
  { label: 'Developer', prompt: 'dev' },
]
