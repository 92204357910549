import BulletList from '../components/BulletList'
import theme from '../styles/theme'
import BackButton from './BackButton'

const bulletlistItems = [
  <div className="flex h-[60px] items-center">
    I’m able to set aside at least 10 minutes ⏰
  </div>,
  <div className="flex h-[60px] items-center">
    I’m in a quiet and comfortable environment 🛋️
  </div>,
  <div className="flex h-[60px] items-center">
    I’m keeping a curious and open mind to my inner world ❤️
  </div>,
]

export default function Instructions({ onBack }: { onBack: () => void }) {
  return (
    <div>
      <BackButton onBack={onBack} />
      <div className="text-center">
        <h2 className={theme.text.h2}>Preparation</h2>
      </div>
      <BulletList
        items={bulletlistItems}
        color="blurple"
        className="mt-8 max-w-[340px] mx-auto"
        liClassName="items-center font-satoshi"
        checkClassName="w-[32px] h-[32px] min-w-[32px]"
      />
    </div>
  )
}
