// @ts-ignore
import NoSleep from '../lib/nosleep'

const noSleep = new NoSleep()

/**
 * Acquire a wake lock to prevent the screen from turning off.
 * Note that this only works in secure contexts (https) and when
 * triggered by a user action.
 */
export default function useWakeLock() {
  return [() => noSleep.enable(), () => noSleep.disable()]
}
