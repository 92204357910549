import throttle from 'lodash/throttle'
import { useCallback, useEffect, useRef, useState } from 'react'
import { IoMdClose, IoMdHelp } from 'react-icons/io'
import { FaArrowUp, FaPlay, FaPause, FaMicrophoneSlash } from 'react-icons/fa'
import { useBlocker, useNavigate, useSearchParams } from 'react-router-dom'
import type { ConversationStatus } from 'vocode/dist/types/conversation'

import { analytics, openTally } from '../analytics'
import Captions from './Captions'
import type { Config } from '../conversation'
import { Conversation } from '../conversation'
import SubscribeOverlay from '../components/SubscribeOverlay'
import Tooltip from '../components/Tooltip'
import { modes, devModes, useCases, mainSession } from '../modes'
import Orb from './Orb'
import { useSession, useSetGrant } from '../session'
import theme from '../styles/theme'
import TipsModal from './TipsModal'
import AudioVisualizer from './Visualizer'
import Header from '../layout/Header'
import useSetRoomHeight from './useSetRoomHeight'
import Sun, { SunState } from '../leadin/Sun'
import useTimer from './useTimer'
import { useIsMobile } from '../utils/useIsMobile'
import ExitModal from './ExitModal'
import { pickUrl } from '../conversation/util'
import { supabase } from '../supabase'
import ConfirmModal from '../components/ConfirmModal'

const TOOLTIP_VERSION = 'v3'

export default function ConversationRoom() {
  const navigate = useNavigate()
  const session = useSession()
  const [mode, setMode] = useState<any>()
  const [shouldResume, setShouldResume] = useState(false)
  const conversation = useRef<Conversation>()
  const [status, setStatus] = useState<ConversationStatus>('idle')
  const [error, setError] = useState<{ message: string }>()
  const [isThinking, setIsThinking] = useState(false)
  const [isTalking, setIsTalking] = useState(true)
  const [showCaptions, setShowCaptions] = useState(true)
  const [showSubscribe, setShowSubscribe] = useState(false)
  const [sendButtonActive, setSendButtonActive] = useState(false)
  const [showPausedControls, setShowPausedControls] = useState(true)
  const setGrant = useSetGrant()
  const [searchParams] = useSearchParams()
  const [showTips, setShowTips] = useState(false)
  const [sunState, setSunState] = useState<SunState>('rise')
  const [checkedRecentSession, setCheckedRecentSession] = useState(false)
  const [showResumePrompt, setShowResumePrompt] = useState(false)
  const [shouldSegue, setShouldSegue] = useState(false)
  const blocker = useBlocker(sunState == 'talk')
  const [transcript, setTranscript] = useState('')
  const panel = useSetRoomHeight()
  const { startTimer, pauseTimer, stopTimer } = useTimer()

  useEffect(() => {
    const mode =
      [...modes, ...devModes, ...useCases, mainSession].find(
        m => m.prompt === searchParams.get('mode')
      ) || mainSession

    setMode(mode)
  }, [searchParams])

  useEffect(() => {
    if (sunState !== 'talk') return
    const beforeUnload = (event: BeforeUnloadEvent) => event.preventDefault()
    window.addEventListener('beforeunload', beforeUnload)
    return () => window.removeEventListener('beforeunload', beforeUnload)
  }, [sunState])

  // look for recent messages to determine if we should prompt the user to
  // resume the previous session
  useEffect(() => {
    if (!mode) return
    
    ;(async () => {
      const cutoffTime = new Date(Date.now() - 1000 * 3600) 
      const recentMessages = await supabase
        .from('messages')
        .select('*, conversations!inner(prompt)')
        .gt('created_at', cutoffTime.toISOString())
        .eq('conversations.prompt', mode.prompt)
        .limit(1)
      if (recentMessages?.data?.length) {
        setShowResumePrompt(true)
      } else {
        setCheckedRecentSession(true)
      }
    })()
  }, [mode])

  const canStart = mode && sunState === 'talk' && checkedRecentSession

  useEffect(() => {
    if (!canStart) return
    if (searchParams.get('autostart') !== 'no') start(mode.audio)

    if ((window as any).fbq) {
      // @ts-expect-error: FB conversion tracking, see index.html
      window.fbq('track', 'ViewContent', {
        content_type: 'Session',
        content_name: mode.prompt,
      })
    }

    // this stops the conversation if the user navigates back in the browser
    return () => conversation.current?.stop()
  }, [mode, canStart])

  const pause = (options?: any) => {
    pauseTimer()
    conversation.current!.stop()
    setShowPausedControls(true)
    if (!options?.skipTracking)
      analytics.track('Session Paused', { sessionName: mode.label })
  }

  if (conversation.current) {
    Object.assign(conversation.current, {
      statusCallback: setStatus,
      errorCallback: (err: any) => {
        setError(err)
        if (err) pause({ skipTracking: true })
      },
      thinkingCallback: setIsThinking,
      talkingCallback: (val: boolean) => {
        setIsTalking(val)
        if (!val) setSendButtonActive(true)
      },
      transcriptCallback: (text: string) => {
        setTranscript(text)
      },
      socketMessageCallback: (message: any) => {
        if (message.type === 'no_access') {
          setShowSubscribe(true)
        } else if (message.type === 'grant_update') {
          setGrant(message)
        } else if (message.type === 'detected_done_phrase') {
          setSendButtonTapped()
          conversation.current?.playSoundEffect('done')
        } else {
          console.log('Unhandled socket message', message)
        }
      },
    })
  }

  const handleResumePrompt = (resume: boolean) => {
    if (resume) {
      setShouldResume(true)
      setShouldSegue(true)
    }
    setShowResumePrompt(false)
    setCheckedRecentSession(true)
  }

  const handleSendButton = useCallback(
    throttle(
      () => {
        conversation.current?.setDeferResponding(false)
        setSendButtonTapped()
      },
      1000,
      { trailing: false }
    ),
    [conversation.current]
  )

  const setSendButtonTapped = () => {
    if (localStorage.tappedDoneButton !== TOOLTIP_VERSION)
      localStorage.tappedDoneButton = TOOLTIP_VERSION
    setSendButtonActive(false)
    setTimeout(() => setSendButtonActive(true), 3000)
    conversation.current?.startTimingResponse()
  }

  const start = async (initialAudioURL?: string) => {
    setStatus('connecting')
    setShowPausedControls(false)

    const config: Config = {
      backendUrl: pickUrl(mode.url),
      audioDeviceConfig: {},
      metadata: {
        accessToken: session.access_token,
        refreshToken: session.refresh_token,
        prompt: mode.prompt,

        // FIXME code smell; this changes after Conversation is init'ed
        resume: shouldResume,
        initialMessageType: shouldSegue ? 'segue' : undefined,
        voice: localStorage.voice || 'Joanne'
      },

      // TODO send to backend to conditionally enable response inhibitor
      enableDoneButton: session.settings.get('enableDoneButton'),
    }
    conversation.current = new Conversation(config)

    const started = await conversation.current.start({
      initialAudioURL,
      resume: shouldResume,
    })

    if (started) {
      if (!shouldResume)
        analytics.track('Conversation Started', { sessionName: mode.label })
      setShouldResume(true)
      startTimer()
      setShouldSegue(false)
    }
  }

  const resume = () => {
    start(mode.audio)
    analytics.track('Session Unpaused', { sessionName: mode.label })
    setShowPausedControls(false)
    setSendButtonActive(true)
  }

  const interrupt = () => {
    conversation.current!.interrupt()
    setIsTalking(false)
    setIsThinking(false)
  }

  const end = () => {
    stopTimer()
    conversation.current!.stop()
    setShouldResume(false)
    setSunState('set')
    analytics.track('Session Ended', { sessionName: mode.label })
  }

  const returnToHome = () => {
    if (session.guest) {
      navigate('/?show-guest-signup=true')
    } else {
      openTally('afterSession', session.user.email)
      navigate('/')
    }
  }

  const autoSendMode = !session.settings.get('enableDoneButton')
  const isSendButtonEnabled =
    sendButtonActive && status === 'connected' && !autoSendMode

  useEffect(() => {
    const handleSpaceBarPress = (event: KeyboardEvent) => {
      if (isSendButtonEnabled && event.code === 'Space') {
        handleSendButton()
      }
    }

    window.addEventListener('keydown', handleSpaceBarPress)

    return () => {
      window.removeEventListener('keydown', handleSpaceBarPress)
    }
  }, [isSendButtonEnabled])

  const shouldShowTooltip = localStorage.tappedDoneButton !== TOOLTIP_VERSION
  const isMobile = useIsMobile()

  ;(window as any).thyself.testSubscribe = () => setShowSubscribe(true)

  const statusLabel =
    status === 'connected'
      ? isTalking
        ? 'Talking'
        : isThinking
        ? 'Thinking...'
        : ''
      : status === 'connecting'
      ? 'Connecting...'
      : ''

  const handleSunOnContinue = () => {
    switch (sunState) {
      case 'rise':
        setSunState('talk')
        break
      case 'set':
        returnToHome()
        break
    }
  }

  const tooltipText = isMobile
    ? 'When you\'re done talking, say "Done" or tap here'
    : 'When you\'re done talking, say "Done", tap here, or press Space'

  return (
    <>
      <Sun sunState={sunState} onContinue={handleSunOnContinue} />
      <main
        className="flex flex-col min-h-[100vh] bg-fixed bg-top bg-cover xs:p-[20px]"
        style={{ backgroundImage: `url("/images/background.jpg")` }}
      >
        <div
          className={`${
            ['rise', 'set'].includes(sunState)
              ? 'opacity-100'
              : 'max-xs:opacity-0 max-xs:pointer-events-none'
            /* Header class is set to absolute to avoid jumpy placement during transitions */
          } absolute transition-opacity duration-[2000ms] w-full xs:left-[20px] xs:right-[20px] xs:w-auto z-20`}
        >
          <Header mobileAltTheme={true} />
        </div>
        <div
          ref={panel}
          className={`${theme.panel.basic} flex flex-col p-5 pt-3 w-full grow max-xs:grow-0 mt-4 xs:mt-[66px] rounded-[40px]`}
        >
          {sunState === 'talk' && showResumePrompt && (
            <ConfirmModal
              isOpen
              onConfirm={() => handleResumePrompt(true)}
              onClose={() => handleResumePrompt(false)}
              title='Resume session?'
              message='Would you like to resume your previous session?'
              confirmLabel='Resume'
              cancelLabel='Start new session'
            />
          )}
          <SubscribeOverlay
            isOpen={showSubscribe}
            onClose={() => setShowSubscribe(false)}
            onConfirm={pause}
          />
          <TipsModal
            isOpen={showTips}
            onClose={() => setShowTips(false)}
            {...{ showCaptions, setShowCaptions }}
          />
          <Captions
            transcript={transcript}
            aria-disabled={!showCaptions}
            className="aria-disabled:opacity-0 h-[12vh] sm:h-[18vh] z-10"
          />
          {sunState === 'talk' && <ExitModal blocker={blocker} />}
          <div className="flex flex-col justify-center items-center grow">
            <Orb {...{ isTalking, isThinking }} />
          </div>
          <div className="text-center flex flex-col grow gap-3 justify-start items-center relative">
            <h2 className="text-textblack text-[20px] h-7">
              {statusLabel}
              {error && (
                <div className="text-red-600 text-center">{error.message}</div>
              )}
            </h2>
            <div className="h-[56px] flex items-center">
              {(isTalking || isThinking) && status == 'connected' && (
                <button
                  className="flex justify-start items-center gap-2 aria-disabled:opacity-0 aria-disabled:hidden transition-all rounded-full border bg-white border-[#D0D5DD] py-[15px] px-[30px] md:border-none h-[56px]"
                  onClick={interrupt}
                >
                  Tap to Interrupt
                  <span className="md:p-2 md:border md:rounded-full">
                    <FaMicrophoneSlash size="20px" />
                  </span>
                </button>
              )}
              {!isTalking && !isThinking && conversation.current?.recorder && (
                <div className="h-[56px] w-[160px] flex justify-center w-full">
                  <AudioVisualizer recorder={conversation.current.recorder} />
                </div>
              )}
            </div>
          </div>
          {/* Main row of buttons */}
          {!showPausedControls && (
            <div className="flex justify-between gap-3 px-5">
              <button
                onClick={pause}
                className={`${theme.button.basic} w-[56px] h-[56px] rounded-full flex justify-center items-center self-center`}
              >
                <FaPause className="w-[20px] h-[20px]" />
              </button>

              <div className="relative">
                <button
                  id="send-button"
                  onClick={handleSendButton}
                  className={`${
                    theme.button.basic
                  } rounded-full w-[72px] h-[72px] ${
                    isSendButtonEnabled ? '' : 'opacity-50'
                  } bg-blurple text-white flex justify-center items-center`}
                >
                  {autoSendMode ? (
                    'AUTO'
                  ) : (
                    <FaArrowUp className="h-[32px] w-[32px]" />
                  )}
                </button>

                {isSendButtonEnabled && (
                  <Tooltip
                    message={tooltipText}
                    className="-left-[99px] w-[270px]"
                    shouldShow={shouldShowTooltip}
                  />
                )}
              </div>

              <button
                onClick={() => setShowTips(true)}
                className={`${theme.button.basic} w-[56px] h-[56px] rounded-full flex justify-center items-center self-center`}
              >
                <IoMdHelp className="w-[24px] h-[24px]" />
              </button>
            </div>
          )}
          {/* Resume/Exit buttons, shown when paused */}
          {showPausedControls && (
            <div className="flex justify-between gap-3 h-[72px] items-center">
              <button
                onClick={resume}
                className={`${theme.button.basic} w-[56px] h-[56px] rounded-full flex items-center justify-center ml-5`}
              >
                <FaPlay />
              </button>
              <button
                onClick={end}
                className={`${theme.button.basic} rounded-full flex items-center justify-between bg-redalert-bg text-redalert h-[56px] px-5`}
              >
                End Session
                <IoMdClose className="w-[24px] h-[24px] ml-[2px] sm:ml-[8px] mr-[-4px]" />
              </button>
              <button
                onClick={() => setShowTips(true)}
                className={`${theme.button.basic} w-[56px] h-[56px] rounded-full flex justify-center items-center self-center place-self-end mr-5`}
              >
                <IoMdHelp className="w-[24px] h-[24px]" />
              </button>
            </div>
          )}
        </div>
      </main>
    </>
  )
}
