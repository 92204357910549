import { useEffect } from 'react'
import SupabaseAuth from '../SupabaseAuth'
import { supabase } from '../supabase'
import { useGrantMaybe, useShouldCountSignIn } from '../session'

export default function LeadInSignup({ onSuccess }: { onSuccess: () => void }) {
  const grant = useGrantMaybe()
  const [, setShouldCountSignIn] = useShouldCountSignIn()

  useEffect(() => {
    setShouldCountSignIn(true)
  }, [])

  useEffect(() => {
    if (grant) onSuccess()
  }, [grant])

  return (
    <SupabaseAuth
      view="sign_up"
      supabaseClient={supabase}
      providers={['google']}
    />
  )
}
