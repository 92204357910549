export interface JournalEntryMessage {
  human: boolean
  created_at: Date
  content: string
}

export interface JournalEntry {
  title: string
  duration: number
  created_at: Date
  summary?: string
  messages: JournalEntryMessage[]
  mode: string
}

const hasDelay = (m1: JournalEntryMessage, m2: JournalEntryMessage) => {
  const t1 = m1.created_at.getTime()
  const t2 = m2.created_at.getTime()
  return t2 - t1 > 20000
}

export const groupMessages = (messages: JournalEntryMessage[]) => {
  const output = []
  let lastSpeaker = null

  for (const m of messages) {
    if (output.length === 0) {
      output.push(m)
      continue
    }

    if (lastSpeaker !== m.human || hasDelay(output[output.length - 1], m)) {
      output.push(m)
    } else {
      output[output.length - 1].content += ' ' + m.content
      output[output.length - 1].created_at = m.created_at
    }
    lastSpeaker = m.human
  }
  return output
}
