import theme from '../styles/theme'
import SunAnimation from '../components/SunAnimation'
import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import useWakeLock from '../room/useWakeLock'

const animations = {
  fadeInButton: {
    opacity: 1,
    transition: { duration: 5, delay: 0.2 },
  },
}

export type SunState = 'rise' | 'talk' | 'set'

export default function Sun({
  sunState,
  onContinue,
}: {
  sunState: SunState
  onContinue: () => void
}) {
  const [textClass, setTextClass] = useState('z-20')
  const TEXT_FADE_SECONDS = 3
  const textSize = window.innerHeight < 500 ? '2xl' : '3xl'

  const [requestWakeLock, releaseWakeLock] = useWakeLock()

  const handleContinue = () => {
    if (sunState === 'rise')
      requestWakeLock()
    else
      releaseWakeLock()
    return onContinue()
  }

  useEffect(() => {
    if (sunState === 'talk') {
      setTimeout(() => {
        setTextClass('hidden')
      }, TEXT_FADE_SECONDS * 1000)
    } else {
      setTextClass('z-20')
    }
  }, [sunState])

  return (
    <>
      <SunAnimation sunState={sunState} />
      <div
        className={`${textClass} flex flex-col text-center mx-auto w-[610px] max-w-[85%] left-0 right-0 absolute`}
      >
        <motion.div
          initial={{ opacity: 1 }}
          animate={
            ['talk'].includes(sunState) ? { opacity: 0 } : { opacity: 1 }
          }
          exit={{ opacity: 0, zIndex: 0 }}
          transition={{ duration: TEXT_FADE_SECONDS }}
          key={'text'}
        >
          <div className="flex flex-col pt-16">
            <div className="mt-16 text-white font-[500] hidden xs:block xs:text-xl">
              {sunState === 'set'
                ? 'Session Complete'
                : 'Your session is about to begin'}
            </div>
            <div className={`text-${textSize} sm:text-5xl text-white leading-snug font-satoshi-bold text-left mt-16 xs:text-center`}>
              {sunState === 'set'
                ? 'Take a moment to pause and thank yourself for showing up today.'
                : 'Before we start, take a moment to slow down, get comfortable,\n' +
                  'and clear distractions.'}
            </div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={animations.fadeInButton}
              exit={{ opacity: 0 }}
              key={['rise', 'talk'].includes(sunState) ? 'rising' : 'setting'}
            >
              <button
                onClick={handleContinue}
                className={`${theme.button.white} mt-16`}
              >
                {sunState === 'set' ? 'Finish' : 'Start Session'}
              </button>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </>
  )
}
