import { useEffect, useState } from 'react'
import { supabase } from '../supabase'
import { useNavigate } from 'react-router-dom'
import theme from '../styles/theme'
import insightsLogo from './insights.png'
import Modal from 'react-modal'
import ReactMarkdown, { Components } from 'react-markdown'
import { CloseButton } from './CloseButton'
import { useSessionMaybe } from '../session'
import { formatISO8601Date } from '../utils/time'

const TallyEmbed = ({ email }: { email: string }) => {
  useEffect(() => {
    // @ts-ignore
    window.Tally.loadEmbeds()
  }, [email])

  return (
    <div className="flex justify-center mx-auto">
      <iframe
        data-tally-src={`https://tally.so/embed/wdYk8q?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1&id=${email}`}
        loading="lazy"
        style={{
          width: '700px',
          maxWidth: '100%',
          height: '382px',
          border: '0',
        }}
        title="How did you like your Insights?"
      />
    </div>
  )
}

type Report = { text: string; created_at: string }

export const InsightsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  const [reportIndex, setReportIndex] = useState<number>(0)
  const [allReports, setAllReports] = useState<Report[]>()
  const navigate = useNavigate()
  const session = useSessionMaybe()

  useEffect(() => {
    const fetchInsights = async () => {
      try {
        const { data: reports, error } = await supabase
          .from('insights')
          .select('*')
          .eq('test', false)
          .order('created_at', { ascending: false })
        if (error || !reports || (reports && reports.length === 0)) {
          return
        }
        setAllReports(reports)
        console.log(`reports: ${reports.length}`)
      } catch (error) {
        console.error(error)
      }
    }

    fetchInsights()
  }, [])

  const report = allReports?.[reportIndex]
  const reportCount = allReports?.length || 0

  const handleStartSession = () => {
    navigate(`/talk?mode=open`)
  }

  const handlePreviousReport = () => {
    if (allReports && reportIndex < reportCount - 1) {
      setReportIndex(reportIndex + 1)
    }
  }

  const handleNextReport = () => {
    if (reportIndex > 0) {
      setReportIndex(reportIndex - 1)
    }
  }

  const components: Components = {
    p: ({ node, children, ...props }) => (
      <p
        style={{ margin: '30px 0 10px', fontSize: '20px', color: '#101828' }}
        {...props}
      >
        {children}
      </p>
    ),
    ul: ({ node, children, ...props }) => (
      <ul
        style={{ paddingLeft: '20px', margin: '10px', listStyleType: 'disc' }}
        {...props}
      >
        {children}
      </ul>
    ),
    ol: ({ node, children, ...props }) => (
      <ol
        style={{
          paddingLeft: '20px',
          margin: '10px',
          listStyleType: 'decimal',
        }}
        {...props}
      >
        {children}
      </ol>
    ),
    li: ({ node, children, ...props }) => (
      <li style={{ margin: '2px', color: '#667085' }} {...props}>
        {children}
      </li>
    ),
  }

  return (
    <Modal
      isOpen={isOpen}
      className={`${theme.panel.basic} max-h-[95vh] -mt-[5px] md:-mt-[10px] lg:md:w-[1020px] text-center relative rounded-xl overflow-hidden`}
      overlayClassName="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
      onRequestClose={onClose}
      closeTimeoutMS={200}
    >
      <CloseButton onClose={onClose} />
      <div className="w-[100%] max-w-[900px] mx-auto font-inter text-[16px] xs:w-full xl:mt-0 relative">
        <div className="flex justify-center items-center text-center">
          <img
            alt="Insights"
            className="w-[24px] h-[24px] sm:w-[34px] sm:h-[34px] mr-2 sm:mr-3"
            src={insightsLogo}
          />
          <h2 className={`${theme.text.h2} text-[36px] xs:text-2xl`}>
            Insights
          </h2>
          <div className="flex items-center justify-center bg-blurple rounded-full h-[22px] w-[44px] text-[12px] text-white -mt-8">
            beta
          </div>
        </div>
        <p
          className={`mt-4 text-center text-lg sm:text-xl text-[#7d7d7d] mb-4`}
        >
          These insights are generated from your sessions
        </p>
        <div
          className="h-[30px] mb-[-29px] relative"
          style={{
            background: 'linear-gradient(to top, transparent, 50%, white)',
          }}
        ></div>
        {report ? (
          <div
            className="max-w-[780px] mx-auto text-left text-textgray xs:mt-0 overflow-auto pb-[60px]"
            style={{ maxHeight: 'calc(95vh - 250px)' }}
          >
            <div className="flex flex-row justify-between mt-6">
              {formatISO8601Date(new Date(report.created_at))}
              <div>
                {reportIndex < reportCount - 1 && (
                  <a
                    onClick={handlePreviousReport}
                    className="text-blurple cursor-pointer"
                  >
                    Previous
                  </a>
                )}
                {reportIndex < reportCount - 1 && reportIndex > 0 && ' • '}
                {reportIndex > 0 && (
                  <a
                    onClick={handleNextReport}
                    className="text-blurple cursor-pointer"
                  >
                    Next
                  </a>
                )}
              </div>
            </div>
            <ReactMarkdown components={components}>{report.text}</ReactMarkdown>
            <div className="italic mt-6">
              Please keep in mind that this is a beta feature. Consider these
              insights as tools for reflection rather than absolute truths. Keep
              what serves you and feel free to disregard the rest.
            </div>
            <div className="italic mt-6">
              New insight reports are generated weekly if you have had 5 or more
              sessions since the last report.
            </div>
            <TallyEmbed email={session?.user.email || ''} />
          </div>
        ) : (
          <div className="flex flex-wrap sm:text-lg mt-[30px] mb-[90px] sm:my-[20vh] sm:mb-[calc(20vh + 60px)] mx-auto w-[760px] max-w-[100%] justify-center items-center text-center px-8 py-4 border-[1px] rounded-lg border-[#D0D5DD]">
            You will receive an insights report after you have completed
            <strong className="ml-1">3 sessions,</strong> each lasting at least five minutes. They are generated weekly.
          </div>
        )}
        <div
          style={{
            background:
              'linear-gradient(to bottom, transparent, 5%, white, 70%, white)',
          }}
          className="w-full mx-auto absolute flex items-center sm:items-end bottom-[-50px] h-[120px]"
        >
          <button
            onClick={handleStartSession}
            className={`${theme.button.fancy} text-lg h-[50px] w-[300px] mx-auto`}
          >
            Start a session
          </button>
        </div>
      </div>
    </Modal>
  )
}
