import React, { useState, useEffect, CSSProperties } from 'react'

interface TooltipProps {
  message: string
  delay?: number // Delay in milliseconds before the tooltip appears
  shouldShow: boolean // Boolean to determine if the tooltip should be shown
  className?: string
}

const Tooltip: React.FC<TooltipProps> = ({
  message,
  delay = 200,
  className,
  shouldShow,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [hasBeenShown, setHasBeenShown] = useState(false)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (!hasBeenShown && shouldShow) {
      timer = setTimeout(() => {
        setIsVisible(true)
        setHasBeenShown(true)
      }, delay)
    }

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [hasBeenShown, delay, shouldShow])

  const tooltipStyle: CSSProperties = {
    position: 'absolute',
    bottom: '100%',
    transform: 'translateY(-10px)',
    backgroundColor: '#6172F3',
    color: 'white',
    padding: '8px 12px', // Assuming standard padding
    borderRadius: '0.5rem', // Tailwind 'rounded-md'
    boxShadow:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1)', // Tailwind 'shadow-lg'
    zIndex: 10, // Ensure it's above most other elements
    fontSize: '0.85rem', // Tailwind 'text-sm'
    // width: '300px',
    // maxWidth: '400px', // Max width to prevent very long tooltips
    textAlign: 'center' as const, // Center the text inside the tooltip
    fontWeight: '600',
  }

  const notchStyle: CSSProperties = {
    position: 'absolute',
    top: '100%',
    left: '50%', // Centered horizontally
    borderWidth: '8px', // Size of the notch
    borderStyle: 'solid',
    borderRadius: '4px 4px 0px 0px', // Rounded bottom
    borderColor: 'transparent transparent #6172F3 transparent', // Match the tooltip background color
    transform: 'translateX(-50%) rotate(180deg)', // Center the notch
  }

  return (
    <>
      {isVisible && (
        <div style={tooltipStyle} className={className}>
          {message}
          <div style={notchStyle} /> {/* The notch at the bottom */}
        </div>
      )}
    </>
  )
}

export default Tooltip
