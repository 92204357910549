import { XMarkIcon } from '@heroicons/react/20/solid'

export const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <div
      className="flex items-center cursor-pointer self-end"
      onClick={onClose}
    >
      <div className="rounded-[16px] bg-ltgray flex items-center justify-center h-[30px] w-[30px]">
        <XMarkIcon className="h-6 w-6 text-[#667085]" />
      </div>
    </div>
  )
}