import * as Sentry from '@sentry/react'

const environment = (import.meta as any).env.VITE_SENTRY_ENV || 'production'

export const setupSentry = () => {
  Sentry.init({
    dsn: 'https://7b5e7267e1d097fef41f474990f07e60@o4506419565756416.ingest.sentry.io/4506578238504960',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment,
  })
}

export const captureException = Sentry.captureException
export const captureMessage = Sentry.captureMessage