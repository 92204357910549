import { ReactNode, memo } from 'react'
import Header from './Header'

const MainLayout = ({ children }: { children: ReactNode }) => {
  return (
    <main
      className="flex flex-col min-h-[100vh] bg-fixed bg-top bg-cover xs:p-[20px]"
      style={{ backgroundImage: `url("/images/background.jpg")` }}
    >
      <Header />
      {children}
    </main>
  )
}

export default memo(MainLayout)
