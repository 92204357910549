import React, { useEffect, useRef, useState } from 'react'

interface CaptionsProps extends React.HTMLProps<HTMLDivElement> {
  transcript: string
}

type Reply = { text: string }

const PAUSE_TIME = 5000 // Time in milliseconds
const SCROLL_BEHAVIOR = 'smooth'

const Captions: React.FC<CaptionsProps> = ({
  transcript,
  className,
  ...rest
}) => {
  const [replies, setReplies] = useState<Reply[]>([])
  const [lastReplyTime, setLastReplyTime] = useState<number>(0)
  const divRef = useRef<HTMLDivElement | null>(null)

  // Handle incoming transcripts
  useEffect(() => {
    const now = Date.now()
    const quickPause = now - lastReplyTime < PAUSE_TIME

    if (quickPause && replies.length > 0) {
      const lastReply = replies[replies.length - 1]
      lastReply.text = `${lastReply.text} ${transcript}`
      setReplies([...replies.slice(0, -1), lastReply])
    } else {
      setReplies([...replies, { text: transcript }])
    }

    setLastReplyTime(now)
  }, [transcript])

  // Handle scrolling
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTo({
        top: divRef.current.scrollHeight,
        behavior: SCROLL_BEHAVIOR,
      })
    }
  }, [replies])

  return (
    <div
      className={`relative aria-disabled:opacity-0 flex justify-center ${className}`}
      {...rest}
    >
      <div
        tabIndex={0}
        ref={divRef}
        role="log" // ARIA role for accessibility
        aria-live="polite" // ARIA live region property
        className="relative overflow-y-auto h-[100%] sm:w-10/12 text-[16px] xs:text-[20px] self-center text-center no-scrollbar"
      >
        <div className="sticky top-0 left-0 w-full h-8 bg-gradient-to-b from-white to-transparent"></div>
        {replies.map((line, index) => (
          <p className="mt-1 xs:mt-3" key={index}>
            {line.text}
          </p>
        ))}
      </div>
    </div>
  )
}

export default Captions
