import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import Modal from 'react-modal'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { analytics } from './analytics'
import { setupSentry } from './utils/sentry'
import './index.css'
import routes from './routes'

setupSentry()
;(window as any).thyself = {}

const router = createBrowserRouter(routes)

analytics.init()

// for in-app navigation
router.subscribe(() => analytics.page())

Modal.setAppElement('#root')

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  </StrictMode>
)
