import type { Session as GoTrueSession } from '@supabase/gotrue-js'
import { create } from 'zustand'
import { useShallow } from 'zustand/react/shallow'
import { supabase } from './supabase'

export type { Session as GoTrueSession } from '@supabase/gotrue-js'
export type AccessGrant = { id: number; minutes_used: number; minutes: number }
export type SettingsProp = 'enableDoneButton'
export type Settings = {
  get: (prop: SettingsProp) => any
  set: (prop: SettingsProp, value: any) => Promise<any>
}
export type Session = GoTrueSession & {
  admin: boolean
  guest?: boolean
  settings: Settings
}

interface SessionStore {
  session: Session | undefined
  setSession: (s: Session | undefined) => void

  grant: AccessGrant | undefined
  setGrant: (g: AccessGrant | undefined) => void

  redirectAfterLogin: string | undefined
  setRedirectAfterLogin: (path: string | undefined) => void

  shouldCountSignIn: boolean
  setShouldCountSignIn: (val: boolean) => void
}

const useSessionStore = create<SessionStore>(set => ({
  session: undefined,
  setSession: (s: Session | undefined) => {
    set({ session: s })
    if (localStorage.developer)
      Object.assign((window as any).thyself, { supabase })
  },

  grant: undefined,
  setGrant: (g: AccessGrant | undefined) => set({ grant: g }),

  redirectAfterLogin: undefined,
  setRedirectAfterLogin: (path: string | undefined) =>
    set({ redirectAfterLogin: path }),

  // Supabase sometimes fires a "SIGNED_IN" event when you load the app in the
  // browser and you already have a valid session. We don't want to count that
  // as a sign-in, so we set this boolean only when we render the app in a
  // logged-out state, and only treat the "SIGNED_IN" event as a real sign-in if
  // this boolean is set.
  shouldCountSignIn: false,
  setShouldCountSignIn: val => set({ shouldCountSignIn: val }),
}))

export const useSessionMaybe = () => useSessionStore(state => state.session)
export const useGrantMaybe = () => useSessionStore(state => state.grant)

export const useSession = (): Session => {
  const session = useSessionMaybe()
  if (!session) throw new Error("can't call useSession when no session is set")
  return session
}

export const useSetSession = () => {
  const setSession = useSessionStore(state => state.setSession)
  return (s: Session | undefined) => {
    if (localStorage.developer) {
      console.log('setSession', s)
      ;(window as any).thyself.session = s
    }
    return setSession(s)
  }
}

export const useSetGrant = () => {
  const setGrant = useSessionStore(state => state.setGrant)
  return (g: AccessGrant | undefined) => {
    if (localStorage.developer) {
      console.log('setGrant', g)
      ;(window as any).thyself.grant = g
    }
    return setGrant(g)
  }
}


export const useRedirectAfterLogin = (): [
  string | undefined,
  (path: string | undefined) => void,
] =>
  useSessionStore(
    useShallow(state => [state.redirectAfterLogin, state.setRedirectAfterLogin])
  )

export const useShouldCountSignIn = (): [boolean, (path: boolean) => void] =>
  useSessionStore(
    useShallow(state => [state.shouldCountSignIn, state.setShouldCountSignIn])
  )

export const useUpdateGrant = (verbose = false) => {
  const grant = useGrantMaybe()
  const setGrant = useSetGrant()

  return (updatedGrant: { id: number; minutes_used: number }) => {
    if (!grant) throw new Error('no grant to update')
    if (grant.id !== updatedGrant.id) {
      console.warn(
        `grant ids do not match: ${typeof grant.id} ${
          grant.id
        }, ${typeof grant.id} ${grant.id}`
      )
      return
    }
    if (verbose)
      console.log('updating grant', grant, updatedGrant)

    return setGrant(grant)
  }
}

const isAdmin = async (userId: string) => {
  const { data } = await supabase
    .from('admins')
    .select('id')
    .eq('user_id', userId)
    .maybeSingle()
  return !!data
}

// TODO: update to retrieve from database instead of matcher
const isGuest = (userEmail?: string): boolean => {
  if (!userEmail) return false
  const pattern = /^anon.*thyself\.ai$/
  return pattern.test(userEmail)
}

const settingsDefaults: Record<SettingsProp, any> = {
  enableDoneButton: true,
}

const getSettings = async (userId: string) => {
  const { data } = await supabase
    .from('user_settings')
    .select('props')
    .eq('user_id', userId)
    .maybeSingle()
  const props = data?.props || {}

  return {
    get(prop: SettingsProp) {
      if (props.hasOwnProperty(prop)) return props[prop]
      return settingsDefaults[prop]
    },

    async set(prop: SettingsProp, value: any) {
      const newProps = { ...props, [prop]: value }
      await supabase
        .from('user_settings')
        .upsert({ props: newProps, user_id: userId }, { onConflict: 'user_id' })
        .eq('user_id', userId)
    },
  }
}

export const makeWrappedSession = async (session: GoTrueSession) => {
  const wrappedSession = {
    ...session,
    admin: await isAdmin(session.user.id),
    guest: isGuest(session.user.email),
    settings: await getSettings(session.user.id),
  }

  return wrappedSession
}

export const useRefreshSession = () => {
  const session = useSession()
  const setSession = useSetSession()
  return async () => {
    setSession(await makeWrappedSession(session))
  }
}

const TESTING_AUTO_SIGNUP = false

export const signUpTempUser = async () => {
  if (TESTING_AUTO_SIGNUP) return mockSignUp()

  const tempUserJSON = localStorage.getItem('tempUser')

  if (tempUserJSON) {
    // Temp user exist in storage, log them in
    const { email, password } = JSON.parse(tempUserJSON)
    return supabase.auth.signInWithPassword({ email, password })
  } else {
    // Create new temp user
    const email = `anon-${crypto.randomUUID()}@thyself.ai`
    const password = crypto.randomUUID()
    localStorage.tempUser = JSON.stringify({ email, password })
    return supabase.auth.signUp({ email, password })
  }
}

export const mockSignUp = async () => {
  // reuse these values when testing, so we don't make a mess in the DB
  const email = 'anon-651b0173-631a-4301-9094-ca8695c65179@thyself.ai'
  const password =
    'db396099ec13bab487401282e0858048936a99537c5bc7dc534b8c49f19e4810'
  localStorage.tempUser = JSON.stringify({ email, password })
  return supabase.auth.signInWithPassword({ email, password })
}
