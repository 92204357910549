// this is forked from https://github.com/supabase/auth-ui/blob/main/packages/react/src/components/Auth/Auth.tsx

import { I18nVariables, merge, VIEWS, en } from '@supabase/auth-ui-shared'
import React, { useEffect, useRef, useState } from 'react'
import {
  ForgottenPassword,
  MagicLink,
  SignIn,
  SignUp,
  UpdatePassword,
  VerifyOtp,
} from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'

const appearance = {
  theme: ThemeSupa,
  style: {
    button: {
      background: 'var(--blurple)',
      borderColor: 'var(--blurple)',
      color: 'white',
      fontSize: '16px',
      borderRadius: '8px',
      fontWeight: '500',
    },
    anchor: { color: 'blue' },
  },
  localization: {
    variables: {
      sign_in: {
        email_label: 'Email',
        password_label: 'Password',
        email_input_placeholder: 'Enter your email',
        password_input_placeholder: 'Enter your password',
      },
    },
  },
}

function Auth({
  supabaseClient,
  providers,
  view = 'sign_in',
  redirectTo,
  onlyThirdPartyProviders = false,
  magicLink = false,
  localization = { variables: {} },
  otpType = 'email',
  additionalData,
  children,
}: any): JSX.Element | null {
  const i18n: I18nVariables = merge(en, localization.variables ?? {})

  const [authView, setAuthView] = useState(view)
  const [defaultEmail, setDefaultEmail] = useState('')
  const [defaultPassword, setDefaultPassword] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [flashingTerms, setFlashingTerms] = useState(false)
  const [signupReferrer, setSignupReferrer] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  const Container = ({ children }: { children: React.ReactNode }) => (
    <div>
      {!onlyThirdPartyProviders && children}
      <div className="flex flex-col items-center text-[13px] gap-[8px] mt-[16px]">
        {authView == 'sign_up' && (
          <a
            href="#"
            className="text-textgray"
            onClick={e => {
              e.preventDefault()
              setAuthView('sign_in')
            }}
          >
            Already have an account?{' '}
            <span className="text-blurple font-semibold">Sign in</span>
          </a>
        )}
        {authView !== 'sign_up' && (
          <a
            href="#"
            className="text-textgray"
            onClick={e => {
              e.preventDefault()
              setAuthView('sign_up')
            }}
          >
            Don't have an account?{' '}
            <span className="text-blurple font-semibold">Sign up</span>
          </a>
        )}
        {authView == 'sign_in' && magicLink && (
          <a
            href="#"
            className="text-textgray"
            onClick={e => {
              e.preventDefault()
              setAuthView('magic_link')
            }}
          >
            Sign in without a password
          </a>
        )}
        {authView == 'magic_link' && (
          <a
            href="#"
            className="text-textgray"
            onClick={e => {
              e.preventDefault()
              setAuthView('sign_in')
            }}
          >
            Sign in with a password or Google account
          </a>
        )}
      </div>
    </div>
  )

  useEffect(() => {
    /**
     * Overrides the authview if it is changed externally
     */
    const { data: authListener } = supabaseClient.auth.onAuthStateChange(
      (event: string) => {
        if (event === 'PASSWORD_RECOVERY') {
          setAuthView('update_password')
        } else if (event === 'USER_UPDATED') {
          setAuthView('sign_in')
        }
      },
    )
    setAuthView(view)

    return () => authListener.subscription.unsubscribe()
  }, [view])

  const emailProp = {
    supabaseClient,
    setAuthView,
    defaultEmail,
    defaultPassword,
    setDefaultEmail,
    setDefaultPassword,
    redirectTo,
    magicLink,
    i18n,
    appearance,
  }

  const flashTerms = () => {
    setFlashingTerms(true)
    setTimeout(() => setFlashingTerms(false), 150)
  }

  const handleSignupReferrerSubmit = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setSignupReferrer(e.target.value)
  }

  // Added to get around bug with input un-focusing after entering text
  const handleKeyDown = (e: any) => {
    if (inputRef && inputRef.current && e.key !== 'Tab') {
      setTimeout(() => {
        // @ts-ignore
        inputRef.current.focus();
      }, 1)
    }
  }

  /**
   * View handler, displays the correct Auth view
   * all views are wrapped in <Container/>
   */
  switch (authView) {
    case VIEWS.SIGN_IN:
      return (
        <Container>
          <h2 className="text-3xl font-satoshi-bold text-gray-900 leading-[38px] text-center mb-3">
            Sign in
          </h2>

          <div className="md:w-[70%] w-[90%] m-auto">
            <SignIn
              {...emailProp}
              providers={providers}
              showLinks={false}
              appearance={appearance}
              localization={{
                variables: {
                  sign_in: {
                    email_label: 'Email',
                    password_label: 'Password',
                    email_input_placeholder: 'Enter your email',
                    password_input_placeholder: 'Enter your password',
                  },
                },
              }}
            />
          </div>
        </Container>
      )
    case VIEWS.SIGN_UP:
      return (
        <Container>
          <div className="text-3xl font-satoshi-bold text-gray-900 leading-[38px] text-center mb-3">
            Sign up
          </div>
          <div className="text-textgray md:w-[70%] w-[90%] m-auto text-center">
            Create an account so Thyself can learn to support you better over
            time.
          </div>
          <div className="md:w-[70%] w-[90%] m-auto relative">
            {authView === 'sign_up' && (
              <div className='mt-6'>
                <label className='text-textgray'>
                  How did you hear about Thyself?
                  <input
                    type='text'
                    placeholder="Name of podcast, friend, etc…"
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                    value={signupReferrer}
                    onChange={handleSignupReferrerSubmit}
                    className={`height-[44px] rounded-[8px] border border-[#d0d5dd] bg-white w-full mt-3 py-[10px] px-[15px] ${flashingTerms ? 'border border-redalert' : ''}`}
                  />
                </label>
                <div
                  className={`w-full mt-6 mb-4 px-[20px] py-[14px] border rounded-[8px] border-[#e3e3e5] flex items-center text-xl ${flashingTerms ? 'border-redalert' : ''}`}
                >
                  <input
                    type='checkbox'
                    className='w-[16px] h-[16px]'
                    checked={acceptedTerms}
                    onChange={() => setAcceptedTerms(!acceptedTerms)}
                    id='terms-checkbox'
                  />
                  <label
                    className='ml-4 text-textgray text-[14px]'
                    htmlFor='terms-checkbox'
                  >
                    I agree to the{' '}
                    <a href='/terms' target='_blank' className='text-blurple'>
                      Terms of Service
                    </a>
                    .
                  </label>
                </div>
              </div>
            )}
            <div className="relative">
              <SignUp
                appearance={appearance}
                supabaseClient={supabaseClient}
                redirectTo={redirectTo}
                magicLink={magicLink}
                showLinks={false}
                additionalData={{...additionalData, signupReferrer}}
                children={children}
                providers={providers}
              />
              <div
                className={`absolute inset-0 ${acceptedTerms && !!signupReferrer ? 'hidden' : ''}`}
                onClick={flashTerms}
              ></div>
            </div>
          </div>
        </Container>
      )
    case VIEWS.FORGOTTEN_PASSWORD:
      return (
        <div className="md:w-[70%] w-[90%] m-auto">
          <ForgottenPassword
            appearance={appearance}
            supabaseClient={supabaseClient}
            // TODO change this so we show VIEWS.UPDATE_PASSWORD
            redirectTo={redirectTo}
            showLinks={false}
          />
        </div>
      )

    case VIEWS.MAGIC_LINK:
      return (
        <Container>
          <div className="text-gray-900 text-center text-2xl font-semibold mb-[10px]">
            Sign in without a password
          </div>
          <div className="text-gray-500 text-center text-lg font-normal mb-[20px]">
            Enter your email address to receive a magic link.
          </div>
          <div className="md:w-[70%] w-[90%] m-auto">
            <MagicLink
              appearance={appearance}
              supabaseClient={supabaseClient}
              redirectTo={redirectTo}
              providers={[]}
            />
          </div>
        </Container>
      )

    case VIEWS.UPDATE_PASSWORD:
      return (
        <div className="md:w-[70%] w-[90%] m-auto">
          <UpdatePassword
            appearance={appearance}
            supabaseClient={supabaseClient}
          />
        </div>
      )
    case VIEWS.VERIFY_OTP:
      return (
        <div className="md:w-[70%] w-[90%] m-auto">
          <VerifyOtp
            appearance={appearance}
            supabaseClient={supabaseClient}
            otpType={otpType}
          />
        </div>
      )
    default:
      return null
  }
}

export default Auth
