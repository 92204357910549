import { Helmet } from 'react-helmet-async'
import theme from '../styles/theme'
import { supabase } from '../supabase'
import { useEffect, useState } from 'react'
import { useSession, useRefreshSession, useGrantMaybe } from '../session'
import type { SettingsProp } from '../session'

export const BUY_URL = 'https://buy.stripe.com/5kA01x6XT74q1jy9AR'
export const MANAGE_URL =
  'https://billing.stripe.com/p/login/cN28ya62s4v3fdK8ww'

export default function Account() {
  const {
    settings,
    user: { email },
  } = useSession()
  const grant = useGrantMaybe()
  const refreshSession = useRefreshSession()
  const [subscriptionStatus, setSubscriptionStatus] = useState<string>()
  const [endDate, setEndDate] = useState<Date>()
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    supabase
      .rpc('subscription_status')
      .maybeSingle()
      .then(({ data }: { data: any }) => {
        if (!data?.status) {
          setSubscriptionStatus('none')
          return
        }
        setSubscriptionStatus(data.status)
        setEndDate(new Date(data.end_date))
      })
  }, [])

  const unlimited = grant?.minutes == -1
  const remaining = unlimited
    ? 'Unlimited'
    : (grant?.minutes || 0) - (grant?.minutes_used || 0)

  const isDoneButtonEnabled = settings.get('enableDoneButton')

  const updateSetting = async (prop: SettingsProp, value: any) => {
    setSaving(true)
    await settings.set(prop, value)
    await refreshSession()
    setSaving(false)
  }

  return (
    <div className={`${theme.panel.basic} flex flex-col gap-3`}>
      <Helmet>
        <title>Thyself - Account</title>
      </Helmet>

      <div className={theme.text.pageTitle}>Your Account</div>

      <div className="grid md:grid-cols-2 gap-4">
        <div className="border-[#d0d5dd] border-[1px] rounded-[8px] p-4 text-center flex flex-col gap-2">
          {subscriptionStatus && (
            <>
              <div>
                Subscription status: {subscriptionStatus} {endDate && 'until '}
                {endDate?.toDateString()}
              </div>
              {subscriptionStatus === 'none' ? (
                <a
                  className={theme.button.fancy}
                  href={`${BUY_URL}?prefilled_email=${email}`}
                  target="_blank"
                >
                  Purchase
                </a>
              ) : (
                <a
                  className={theme.button.basic}
                  href={MANAGE_URL}
                  target="_blank"
                >
                  Manage
                </a>
              )}
            </>
          )}
        </div>
        <div className="border-[#d0d5dd] border-[1px] rounded-[8px] p-4 text-center">
          Session minutes remaining
          <div className="text-3xl font-bold mt-1">{remaining}</div>
        </div>
      </div>

      {localStorage.developer && (
        <>
          <div className={`${theme.text.pageTitle} mt-6`}>Settings</div>
          <div
            className={`border-[#d0d5dd] border-[1px] rounded-[8px] p-4 flex justify-between items-center ${
              saving ? 'opacity-50' : ''
            }`}
          >
            <div
              onClick={() =>
                updateSetting('enableDoneButton', !isDoneButtonEnabled)
              }
            >
              <div className="font-satoshi-bold">
                Use Send button (&#x21E7;)
              </div>
              <div>
                Make the bot wait to respond until you press the &#x21E7;
                button. When this is disabled, the bot will respond
                automatically after you stop talking.
              </div>
            </div>
            <input
              type="checkbox"
              className="w-[30px] h-[30px] ml-4 grow"
              checked={isDoneButtonEnabled}
              onChange={() =>
                updateSetting('enableDoneButton', !isDoneButtonEnabled)
              }
            />
          </div>
        </>
      )}
    </div>
  )
}
