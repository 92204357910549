// @ts-ignore
import UAParser from 'ua-parser-js'

export const useIsDeviceOld = () => {
  const parser = new UAParser()
  const os = parser.getOS()
  const majorVersion = parseInt(os.version.split('.')[0])
  // iPhone 7, budget Android phones from 2019
  // this is a rough cutoff, but it's something
  return (os.name === 'iOS' && majorVersion <= 15) || 
    (os.name === 'Android' && majorVersion <= 10)
}
