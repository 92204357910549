import BulletList from '../components/BulletList'
import { FaLock } from 'react-icons/fa'
import theme from '../styles/theme'
import { useNavigate } from 'react-router-dom'
import { BUY_URL } from '../pages/Account'

const journalListItems = [
  <div>Unlimited session time</div>,
  <div>Personalized sessions</div>,
  <div>Session journal with summaries and transcripts</div>,
  <div>Priority customer support</div>,
]
export const Subscribe = ({ email }: { email?: string }) => {
  const navigate = useNavigate()
  const handleSubscribe = (email?: string) => {
    if (email) {
      // User is logged in already, send directly to stripe
      window.location.href = `${BUY_URL}?prefilled_email=${email}`
    } else {
      navigate('/auth?show=signup&next=/journal')
    }
  }

  return (
    <div className="w-[384px] max-w-[90%] my-6 mx-auto font-inter flex flex-col items-center justify-center border-[#d0d5dd] border-[1px] rounded-[16px] p-5 sm:p-7">
      <FaLock className="bg-blurple text-white text-2xl rounded-full h-12 w-12 p-3" />
      <div className="mt-2 text-xl font-bold max-w-[290px]">
        Subscribe to unlock <span className="text-blurple">Journal</span> and
        save your past sessions
      </div>
      <div className="mt-1 text-4xl font-bold">$29/month</div>
      <div className="mt-1 text-textgray text-lg">Thyself Membership</div>
      <BulletList
        items={journalListItems}
        color="blurple"
        className="mt-8 mx-auto text-textgray text-lg"
        liClassName="items-center text-left"
        checkClassName="w-[24px] h-[24px] min-w-[24px] mr-3"
      />
      <button
        className={`${theme.button.decision} mt-8`}
        onClick={() => handleSubscribe(email)}
      >
        {email ? 'Subscribe' : 'Sign up to subscribe'}
      </button>
    </div>
  )
}
