import fm from 'front-matter'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useSession } from '../session'
import { supabase } from '../supabase'
import { useEffect, useState } from 'react'
import theme from '../styles/theme'

export default function AdminLoader() {
  const session = useSession()
  const navigate = useNavigate()
  const [prompts, setPrompts] = useState<any[]>([])

  const fetchPrompts = () =>
    supabase
      .from('prompts')
      .select('*')
      .order('name')
      .then(res => setPrompts(res.data!))

  useEffect(() => {
    if (!session.admin) return navigate('/')
    fetchPrompts()
  }, [])

  return prompts.length > 0 ? (
    <Admin prompts={prompts} onSave={fetchPrompts} />
  ) : (
    <>Loading...</>
  )
}

function Admin({ prompts, onSave }: { prompts: any[]; onSave: () => void }) {
  const navigate = useNavigate()

  const [id, setId] = useState(prompts[0].id)
  const [text, setText] = useState(prompts[0].text)
  const [dirty, setDirty] = useState(false)
  const [justSaved, setJustSaved] = useState(false)
  const [bodyLength, setBodyLength] = useState(0)

  const changeSelected = (id: number) => {
    if (dirty && !window.confirm('Discard your changes?')) return

    setId(id)
    setText(prompts.find(p => p.id === id)!.text)
    setDirty(false)
  }

  const updateText = (newText: string) => {
    setText(newText)
    try {
      setBodyLength(fm(newText).body.split(' ').length)
    } catch (e) {
      console.warn(e)
    }
    if (!dirty && newText != prompts.find(p => p.id === id)!.text)
      setDirty(true)
  }

  const undo = () => {
    setText(prompts.find(p => p.id === id)!.text)
    setDirty(false)
  }

  const save = () => {
    supabase
      .from('prompts')
      .update({ text })
      .eq('id', id)
      .then(() => {
        setDirty(false)
        setJustSaved(true)
        onSave()
        setTimeout(() => setJustSaved(false), 3000)
      })
  }

  const run = () => {
    const mode = prompts.find(p => p.id === id)!.name
    navigate(`/?mode=${mode}`)
  }

  return (
    <div className="flex flex-col flex-1 bg-white mt-4 px-4 md:px-8 py-6 md:py-10 rounded-[40px] md:w-[800px] md:mx-auto">
      <Helmet>
        <title>Thyself - Admin</title>
      </Helmet>
      <div className={`${theme.text.sectionHeader} mb-5`}>Prompt Editor</div>
      <div className="flex-1 flex min-h-[100%] gap-4">
        <div className="flex flex-col justify-between">
          <div className="flex flex-col gap-2">
            <div>Pick a prompt to edit</div>
            <select
              onChange={e => changeSelected(Number(e.target.value))}
              className="border-[1px] rounded-[9px] py-1 px-2 border-[#D0D5DD]"
              value={id}
            >
              {prompts.map(p => (
                <option key={p.id} value={p.id}>
                  {p.name}
                </option>
              ))}
            </select>
            <button
              className={`${theme.button.basic} text-red-600`}
              disabled={!dirty}
              onClick={undo}
            >
              Revert
            </button>
          </div>
          <div className="flex flex-col gap-2">
            <div className="text-center">{bodyLength} words in body</div>
            <button
              className={theme.button.basic}
              disabled={!dirty}
              onClick={save}
            >
              {justSaved ? 'Saved!' : 'Save'}
            </button>
            <button
              className={theme.button.basic}
              disabled={dirty}
              onClick={run}
            >
              Run
            </button>
          </div>
        </div>
        <textarea
          id="prompt_text"
          value={text}
          onChange={e => updateText(e.target.value)}
          className="w-[100%] border-[1px] rounded-[9px] py-1 px-2 border-[#D0D5DD] min-h-[100%] flex-1"
        />
      </div>
    </div>
  )
}
