import Modal from 'react-modal'
import { supabase } from '../supabase'
import SupabaseAuth from '../SupabaseAuth'
import { useEffect, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import {
  signUpTempUser,
  useSessionMaybe,
  useShouldCountSignIn,
} from '../session'
import { IoMdClose } from 'react-icons/io'
import BreadCrumbs from './BreadCrumbs'
import theme from '../styles/theme'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useIsMobile } from '../utils/useIsMobile'

const AUTO_SIGNUP = true
export default function SignupModal({
  isOpen,
  onClose,
  isGuestSignup,
}: {
  isOpen: boolean
  onClose: () => void
  isGuestSignup: boolean
}) {
  const [showSignup, setShowSignup] = useState(false)
  const [skipBenefits, setSkipBenefits] = useState(false)
  const session = useSessionMaybe()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const handleClose = () => {
    onClose()
    setShowSignup(false)
  }

  useEffect(() => {
    if (
      !session?.user.email?.match(/^anon.*@thyself.ai$/) &&
      params.get('show-guest-signup')
    ) {
      handleClose()
      navigate('/')
    }
  }, [session, params])

  if (AUTO_SIGNUP && !isGuestSignup && !showSignup && !localStorage.wasTempUser)
    return (
      <AutoSignupModal
        {...{ isOpen, onClose: handleClose }}
        setShowSignup={() => {
          setShowSignup(true)
          setSkipBenefits(true)
        }}
      />
    )

  return (
    <NormalSignupModal
      {...{ isOpen, onClose: handleClose, isGuestSignup, skipBenefits }}
    />
  )
}

function AutoSignupModal({
  isOpen,
  onClose,
  setShowSignup,
}: {
  isOpen: boolean
  onClose: () => void
  setShowSignup: (show: boolean) => void
}) {
  const [, setShouldCountSignIn] = useShouldCountSignIn()
  const autoSignup = async () => {
    setShouldCountSignIn(true)
    const { error } = await signUpTempUser()
    if (error) {
      // TODO - handle error
      console.error(error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="rounded-[30px] p-8 xs:w-[400px] md:max-w-[80vw] outline-none bg-white"
      overlayClassName="z-40 fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center pb-[var(--mobile-safari-toolbar-height)]"
      closeTimeoutMS={200}
      preventScroll={true}
    >
      <div className="flex flex-col gap-6 max-xs:min-w-[65vw]">
        <button
          className={`${theme.button.fancy} font-satoshi text-lg py-3`}
          onClick={() => setShowSignup(true)}
        >
          Sign up / Sign in
        </button>
        <button
          className={`${theme.button.fancy} font-satoshi text-lg py-3`}
          onClick={autoSignup}
        >
          Try it first
        </button>
      </div>
    </Modal>
  )
}

function NormalSignupModal({
  isOpen,
  onClose,
  isGuestSignup,
  skipBenefits,
}: {
  isOpen: boolean
  onClose: () => void
  isGuestSignup: boolean
  skipBenefits: boolean
}) {
  const [, setShouldCountSignIn] = useShouldCountSignIn()
  const [currentPage, setCurrentPage] = useState(skipBenefits ? 1 : 0)

  const handlers = useSwipeable({
    onSwipedLeft: () => setCurrentPage(1),
    onSwipedRight: () => setCurrentPage(0),
    delta: 30,
    trackTouch: true,
    trackMouse: true,
  })

  useEffect(() => {
    setShouldCountSignIn(true)
  }, [])

  // have to use JS for this so we don't render both views below, because that
  // renders SupabaseAuth twice, which raises console warnings because
  // Supabase's auth components use unique ids
  const isMobile = useIsMobile()

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="w-[100vw] p-0 md:p-3 md:w-[840px] md:max-w-[80vw] outline-none"
      overlayClassName="z-40 fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center pb-[var(--mobile-safari-toolbar-height)]"
      closeTimeoutMS={200}
      preventScroll={true}
    >
      {isMobile ? (
        <div
          {...handlers}
          className="flex flex-col rounded-[31px] bg-cover bg-center md:hidden max-w-[393px] mx-auto"
          style={{ backgroundImage: `url("/images/background.jpg")` }}
        >
          {currentPage === 0 ? (
            <div className="p-5">
              <div className="flex justify-end">
                <button
                  onClick={onClose}
                  className="w-8 h-8 rounded-full bg-ltgray flex justify-center items-center"
                >
                  <IoMdClose size={24} />
                </button>
              </div>
              <h2 className="text-[36px] font-satoshi-bold text-white leading-[38px] text-center mb-3">
                {isGuestSignup ? 'Save your progress' : 'Get Started for Free'}
              </h2>
              <h3 className="text-center text-white text-lg leading-6 mb-5">
                Create an account so Thyself can grow with you over time!
              </h3>
              <div className="flex flex-col gap-5">
                <Benefits />
              </div>
              <div className="flex flex-col gap-5 justify-end items-center mt-10">
                <div onClick={() => setCurrentPage((currentPage + 1) % 2)}>
                  <BreadCrumbs step={0} totalSteps={2} />
                </div>
                <button
                  onClick={() => setCurrentPage(1)}
                  className="bg-blurple py-2 rounded-[40px] w-[300px] h-[50px] flex items-center justify-center text-[16px] font-[600] cursor-pointer border-none transition-all duration-200 xs:hover:bg-[#0D2AE7] xs:rounded-[8px] xs:w-[147px] bg-white text-black"
                >
                  Next
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-[#fff] rounded-[30px] p-5">
              <div className="flex justify-end">
                <button
                  onClick={onClose}
                  className="w-8 h-8 rounded-full bg-ltgray flex justify-center items-center"
                >
                  <IoMdClose size={24} />
                </button>
              </div>
              <SupabaseAuth
                view="sign_up"
                supabaseClient={supabase}
                providers={['google']}
              />
              <div className="flex flex-col gap-5 justify-end items-center mt-5">
                <div onClick={() => setCurrentPage((currentPage + 1) % 2)}>
                  <BreadCrumbs step={1} totalSteps={2} />
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="md:mt-0 hidden md:flex flex-col md:flex-row rounded-[31px] bg-cover"
          style={{ backgroundImage: `url("/images/background.jpg")` }}
        >
          <div className="bg-[#fff] rounded-[30px] py-6 md:py-[50px] px-2 md:px-6 md:w-[533px]">
            <SupabaseAuth
              view="sign_up"
              supabaseClient={supabase}
              providers={['google']}
            />
          </div>
          <div className="p-5 hidden md:flex flex-col justify-center gap-5 md:max-w-[340px]">
            <Benefits />
          </div>
        </div>
      )}
    </Modal>
  )
}

const Benefits = () => (
  <>
    <div className="rounded-lg bg-[#fff] p-4 border-blurple border-[1px] max-xs:flex-1">
      <div className="font-satoshi mb-1 text-xl">
        Get <span className="text-blurple">Access</span> to
      </div>
      <ul className="list-disc pl-4 text-textgray text-lg">
        <li>60 minutes of free session time, no credit card needed</li>
        <li>Sessions that become more personalized over time</li>
      </ul>
    </div>
    <div className="rounded-lg bg-[#fff] p-4 border-[#d92d20] border-[1px] max-xs:flex-1">
      <div className="font-satoshi mb-1 text-xl">
        We will <span className="text-[#d92d20]">Never</span>
      </div>
      <ul className="list-disc pl-4 text-textgray text-lg">
        <li>Send you spam email</li>
        <li>Sell your data to third parties</li>
      </ul>
    </div>
  </>
)
