import { useEffect, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'
import { create } from 'zustand'
import { analytics } from '../analytics'
import LeadInSignup from './LeadInSignup'
import SessionGuide from './SessionGuide'
import SoundCheck from './SoundCheck'
import Instructions from './Instructions'
import BreadCrumbs from '../components/BreadCrumbs'
import theme from '../styles/theme'
import debounce from 'lodash/debounce'
import { useSessionMaybe } from '../session'

const ENABLE_SOUND_CHECK = true

export type LeadInStep = 'signup' | 'instructions' | 'guide' | 'sound' | 'done'

type StepConfig = {
  name: LeadInStep
  component?: (...args: any[]) => JSX.Element
}

const allSteps: StepConfig[] = [
  { name: 'instructions', component: Instructions },
  ENABLE_SOUND_CHECK && { name: 'sound', component: SoundCheck },
  { name: 'guide', component: SessionGuide },
  { name: 'signup', component: LeadInSignup },
  { name: 'done' },
]

const firstStep = allSteps[0].name

// we store the current step in Zustand so that it persists even when ancestor
// components re-render due to session changes

interface StepStore {
  step: LeadInStep
  setStep: (step: LeadInStep) => void
}

const useStepStore = create<StepStore>(set => ({
  step: firstStep,
  setStep: (step: LeadInStep) => set({ step }),
}))

const makeSteps = (): StepConfig[] => allSteps.filter(x => x) as StepConfig[]

export default function LeadIn() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { step, setStep: innerSetStep } = useStepStore()
  const session = useSessionMaybe()

  const setStep = (nextStep: LeadInStep) => {
    if (nextStep == 'done') {
      navigate(`/talk?mode=${searchParams.get('mode')}`)
      setTimeout(() => innerSetStep(firstStep), 1)
    } else {
      innerSetStep(nextStep)
    }
  }

  const stepsConfig = useMemo(() => {
    const steps = makeSteps()

    if (session && step !== 'signup') {
      const si = steps.findIndex(s => s.name === 'signup')
      steps.splice(si, 1)
    }

    return steps
  }, [session, step])

  useEffect(() => {
    if (
      !session &&
      stepsConfig.findIndex(s => s.name === step) >
        stepsConfig.findIndex(s => s.name === 'signup')
    ) {
      setStep(firstStep)
    }
  }, [])

  const handlers = useSwipeable({
    onSwipedLeft: () => setNextStep(),
    onSwipedRight: () => setPrevStep(),
    delta: 30,
    trackTouch: true,
    trackMouse: true,
  })

  const scroller = useRef<HTMLDivElement>(null)

  const setNextStep = () => {
    if (step === 'signup' && !session) {
      return
    }

    const currentIndex = stepsConfig.findIndex(s => s.name === step)
    const nextStep = stepsConfig[currentIndex + 1]
    setStep(nextStep.name)
    analytics.track(`Confirmed Lead-in: ${step}`)
    scroller.current?.scrollTo(0, 0)
  }

  const setPrevStep = () => {
    const currentIndex = stepsConfig.findIndex(s => s.name === step)
    if (currentIndex === 0) {
      navigate('/')
      return
    }

    const prevStep = stepsConfig[currentIndex - 1]
    setStep(prevStep.name)
    scroller.current?.scrollTo(0, 0)
  }

  const container = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (window.innerWidth > theme.breakpoint.xs) return

    const resize = debounce(
      () => {
        if (!container.current) return
        const height = window.innerHeight

        container.current.style.height = `calc(${height}px - 84px)`
        window.scrollTo(0, 0)
        document.body.style.overflow = 'hidden'
      },
      100,
      { leading: true }
    )

    resize()
    window.addEventListener('resize', resize)

    return () => {
      window.removeEventListener('resize', resize)
      document.body.style.overflow = 'auto'
    }
  }, [container.current])

  if (step === 'done') return null

  const stepData = stepsConfig.find(s => s.name === step)!
  const Component = stepData.component!

  const handleSuccess = () => {
    if (step === 'signup') {
      setNextStep()
    }
  }

  return (
    <div
      ref={container}
      className={`${theme.panel.basic} max-xs:p-0 max-xs:overflow-hidden max-xs:bg-white max-xs:grow-0 xs:grow`}
    >
      <div className="flex flex-col grow h-full" {...handlers}>
        <div className="xs:hidden w-full h-[32px] min-h-[32px] mb-[-32px] bg-gradient-to-t from-transparent to-white z-10"></div>
        <div ref={scroller} className="grow max-xs:overflow-scroll max-xs:p-6">
          <Component onBack={setPrevStep} onSuccess={handleSuccess} />
        </div>
        <div className="xs:hidden w-full h-[32px] min-h-[32px] mt-[-32px] bg-gradient-to-t from-white to-transparent z-10"></div>
        <div className="w-full max-xs:bg-white py-4 flex flex-col items-center justify-center">
          {step !== 'signup' && (
            <div className="grow flex items-end justify-center">
              <button onClick={setNextStep} className={theme.button.continue}>
                Continue
              </button>
            </div>
          )}
          <div className="flex justify-center mt-4 xs:hidden">
            <BreadCrumbs
              step={stepsConfig.findIndex(s => s.name === step)}
              totalSteps={stepsConfig.length - 1}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
