import type { Blocker } from 'react-router-dom'
import ConfirmModal from '../components/ConfirmModal'

export default function ExitModal({ blocker }: { blocker: Blocker }) {
  const isOpen = blocker.state == 'blocked'
  const onClose = () => blocker.reset && blocker.reset()

  return (
    <ConfirmModal
      variant="alert"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => blocker.proceed && blocker.proceed()}
      title="End Session"
      message="This will end your session. Are you sure?"
      cancelLabel="Cancel"
      confirmLabel="End"
    />
  )
}
