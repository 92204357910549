import { FiAlertCircle, FiHelpCircle } from 'react-icons/fi'
import Modal from 'react-modal'
import theme from '../styles/theme'

export default function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
  disabled,
  title,
  message,
  cancelLabel,
  confirmLabel,
  variant = 'question',
}: {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  disabled?: boolean
  title: string
  message: string
  cancelLabel?: string
  confirmLabel?: string
  variant?: 'question' | 'alert'
}) {
  return (
    <Modal
      isOpen={isOpen}
      className={`${theme.panel.basic} xs:!h-auto gap-4 text-center relative rounded-xl xs:rounded-xl sm:rounded-xl max-w-[90%] sm:!w-[400px]`}
      overlayClassName="fixed inset-0 z-40 bg-black bg-opacity-50 flex items-center justify-center"
      onRequestClose={onClose}
      closeTimeoutMS={200}
    >
      <div className="flex flex-col text-center justify-center items-center">
        {variant === 'alert' ? (
          <FiAlertCircle className="bg-[#FEE4E2] rounded-full text-[#D92D20] p-[12px] w-[48px] h-[48px]" />
        ) : (
          <FiHelpCircle className="bg-[#eff0fd] rounded-full text-blurple p-[12px] w-[48px] h-[48px]" />
        )}
        <div className="text-textblack mt-4 text-[18px]">{title}</div>
        <div className="text-textgray text-[14px] mt-2">{message}</div>
        <div className="flex flex-col justify-center mt-6 w-full xs:flex-row xs:w-auto">
          <button
            className={`${theme.button.basic} w-full h-[44px] text-[16px] order-2 mt-2 rounded-full xs:rounded-[8px] xs:mt-0 xs:order-1 xs:w-[170px]`}
            onClick={onClose}
          >
            {cancelLabel || 'Cancel'}
          </button>
          <button
            disabled={disabled}
            className={`${
              theme.button[variant === 'alert' ? 'cancel' : 'decision']
            } w-full h-[44px] text-[16px] rounded-full order-1 xs:ml-3 xs:rounded-[8px] xs:order-2 xs:w-[170px]`}
            onClick={onConfirm}
          >
            {confirmLabel || 'Confirm'}
          </button>
        </div>
      </div>
    </Modal>
  )
}
