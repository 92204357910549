import Account from './pages/Account'
import Admin from './pages/Admin'
import Auth from './Auth'
import ConversationRoom from './room'
import JournalIndex from './journal'
import JournalEntry from './journal/JournalEntry'
import Home from './pages/Home'
import Privacy from './pages/Privacy'
import LeadIn from './leadin/LeadIn'
import Terms from './leadin/Terms'

export default [
  {
    path: '/',
    element: (
      <Auth>
        <Home />
      </Auth>
    ),
  },
  { path: 'auth', element: <Auth required standalone /> },
  {
    path: 'admin',
    element: (
      <Auth required>
        <Admin />
      </Auth>
    ),
  },
  {
    path: 'terms',
    element: (
      <Auth>
        <Terms inline={false} />
      </Auth>
    ),
  },
  {
    path: 'privacy',
    element: (
      <Auth>
        <Privacy />
      </Auth>
    ),
  },
  {
    path: 'account',
    element: (
      <Auth required>
        <Account />
      </Auth>
    ),
  },
  {
    path: 'journal/:id',
    element: (
      <Auth required>
        <JournalEntry />
      </Auth>
    ),
  },
  {
    path: 'journal',
    element: (
      <Auth>
        <JournalIndex />
      </Auth>
    ),
  },
  {
    path: 'start',
    element: (
      <Auth>
        <LeadIn />
      </Auth>
    ),
  },
  {
    path: 'talk',
    element: (
      <Auth required useLayout={false}>
        <ConversationRoom />
      </Auth>
    ),
  },
]