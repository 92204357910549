// @ts-ignore
import UAParser from 'ua-parser-js'
import type { AudioEncoding, AudioConfigStartMessage } from 'vocode'
import { supabase } from '../supabase'
import { captureMessage } from '../utils/sentry'

// this wrapper is necessary because `new WebSocket()` may throw errors that
// can't be caught normally
export const createWebSocket = async (
  url: string,
  timeout: number = 15
): Promise<WebSocket> =>
  new Promise((resolve, reject) => {
    const socket = new WebSocket(url)

    const wait = setTimeout(
      () => reject(new Error("Couldn't connect (timed out)")),
      timeout * 1000
    )

    socket.onopen = () => {
      clearTimeout(wait)
      resolve(socket)
    }
    socket.onerror = event => {
      clearTimeout(wait)
      const error = new Error("Couldn't connect")
      ;(error as any).event = event
      reject(error)
    }
    return socket
  })

export const VAD_REALLY_SPEAKING_DELAY_MS = 500

const latencyTimerLog: any[] = []

export const markTime = (name: string, host?: string) => {
  if (!localStorage.enableVAD) return

  const now = new Date().getTime()
  const last = latencyTimerLog[latencyTimerLog.length - 1]
  if (name !== 'speechEnd' && last?.name == name) return

  latencyTimerLog.push({ name, time: now })

  if (host && last) {
    if (last.name === 'response') return

    const ms = now - last.time
    console.log(`timer: ${last.name} -> ${name}: ${ms} ms`)
    supabase
      .from('timing_logs')
      .insert({
        start_event: last.name,
        end_event: name,
        time: ms,
        host,
        user_agent: navigator.userAgent,
      })
      .then(
        _ => {},
        error => console.error(error)
      )
  }
}

const DEFAULT_CHUNK_SIZE = 2048

export const getAudioConfigStartMessage = (
  config: {
    chunkSize?: number | undefined
    downsampling?: number | undefined
    conversationId?: string | undefined
    subscribeTranscript?: boolean | undefined
    metadata: any
  },
  inputAudioMetadata: { samplingRate: number; audioEncoding: AudioEncoding },
  outputAudioMetadata: { samplingRate: number; audioEncoding: AudioEncoding }
): AudioConfigStartMessage => ({
  // @ts-expect-error
  type: 'websocket_audio_config_start_with_metadata',
  inputAudioConfig: {
    samplingRate: inputAudioMetadata.samplingRate,
    audioEncoding: inputAudioMetadata.audioEncoding,
    chunkSize: config.chunkSize || DEFAULT_CHUNK_SIZE,
    downsampling: config.downsampling,
  },
  outputAudioConfig: {
    samplingRate: outputAudioMetadata.samplingRate,
    audioEncoding: outputAudioMetadata.audioEncoding,
  },
  conversationId: config.conversationId,
  subscribeTranscript: config.subscribeTranscript,
  metadata: config.metadata,
})

export const loadBalancedUrl = (url: string) => {
  const val = Math.random()

  // GPT-4-Turbo: 450k, 300k, 300k = 1050k total
  // so normalized weights are 0.428, 0.285, 0.285
  // const [w1, w2] = [0.428, 0.713]

  let host
  if (val < 0.5) {
    host = 'wss://dev21.thyself.ai'
  } else {
    host = 'wss://dev22.thyself.ai'
  }

  const from = /wss:\/\/.*.thyself.ai/
  return url.replace(from, host)
}

const ALWAYS_LOAD_BALANCE = true
const DEFAULT_URL = 'wss://dev5.thyself.ai/conversation'

export const pickUrl = (inputUrl?: string) => {
  let url = inputUrl || DEFAULT_URL
  const override =
    localStorage.developerBackendOverride || (ALWAYS_LOAD_BALANCE && 'lb')
  if (override) {
    const from = /wss:\/\/.*.thyself.ai/
    if (override === 'local') {
      url = url.replace(from, 'wss://lw-3000.ngrok.dev')
    } else if (override === 'replit') {
      url = url.replace(from, 'wss://lawrence-bot-backend.thyself.repl.co')
    } else if (override === 'lb') {
      url = loadBalancedUrl(url)
    } else {
      url = url.replace(from, `wss://${override}.thyself.ai`)
    }
  }
  if (url !== DEFAULT_URL) console.log({ url })
  return url
}

export const getMicStream = async () => {
  const { mediaDevices } = navigator
  let micStream = await mediaDevices.getUserMedia({ audio: true })

  const os = new UAParser().getOS()
  if (os.name !== 'iOS') return micStream

  // work around an issue in Mobile Safari where AirPods don't get used as the
  // audio device, possibly due to a bug in iOS 17.x
  const selectedId = micStream.getAudioTracks()[0].getSettings().deviceId

  const devices = (await mediaDevices.enumerateDevices()).filter(
    d => d.kind == 'audioinput'
  )

  // assume that if there are multiple audio devices, we shouldn't use the
  // iPhone microphone
  if (devices.length > 1) {
    const selectedDevice = devices.find(d => d.deviceId == selectedId)
    if (selectedDevice!.label == 'iPhone Microphone') {
      const otherDevice = devices.find(d => d.label !== 'iPhone Microphone')
      const log = `switching from ${selectedDevice!.label} to ${otherDevice!.label} (full list: ${devices.map(d => d.label).join(', ')})`
      captureMessage(log)
      micStream = await mediaDevices.getUserMedia({
        audio: { deviceId: otherDevice!.deviceId },
      })
    }
  }

  return micStream
}
