import { ArrowSmallLeftIcon } from '@heroicons/react/20/solid'

export default function BackButton({ onBack }: { onBack: () => void }) {
  return (
    <div
      className="back hidden xs:flex items-center cursor-pointer"
      onClick={onBack}
    >
      <div className="rounded-[16px] bg-ltgray flex items-center justify-center h-[30px] w-[30px]">
        <ArrowSmallLeftIcon className="h-6 w-6 text-[#667085]" />
      </div>
      <div className="text-[#667085] font-medium px-3">Back</div>
    </div>
  )
}
