import { ReactNode } from 'react'
import BulletList from '../components/BulletList'
import theme from '../styles/theme'
import BackButton from './BackButton'

const Item = (props: { title: string; children: ReactNode }) => {
  return (
    <>
      <h2 className={`mb-1 ${theme.text.cardTitle}`}>{props.title}</h2>
      <div className="text-[16px] text-textgray">{props.children}</div>
    </>
  )
}

export default function SessionGuide({ onBack }: { onBack: () => void }) {
  const items = [
    <Item title="Share">
      Begin by telling Thyself about something you want to work through. If you
      don't have something in mind, that's okay; just start and Thyself will
      guide you.
    </Item>,
    <Item title="Feel">
      Thyself will lead you through a process of resolving your emotions through
      exploring your feelings and physical sensations.
    </Item>,
    <Item title="Integrate">
      Take away key insights into your emotions, and set intentions to ground
      these insights into your daily life.
    </Item>,
  ]

  return (
    <div>
      <BackButton onBack={onBack} />
      <div className="text-center">
        <h2 className={theme.text.h2}>How to Have a Session</h2>
      </div>

      <div className="grid grid-rows-[1fr_auto] h-full">
        {/* MAIN CONTENT SECTION */}
        <div className="xs:max-w-[450px] xs:mx-auto xs:my-4 overflow-auto">
          <BulletList
            items={items}
            color="blurple"
            className="my-6"
            numberList
          />

          <div className="rounded-[8px] border-blurple border-[1px] p-4 mt-2 mb-4">
            <h2 className={theme.text.cardTitle}>
              <span className="text-blurple">Tip:</span> You're In Charge
            </h2>
            <div className="text-textgray">
              Ask for things! Steer the session however you like.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
