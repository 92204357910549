import React from 'react'

interface BreadCrumbsProps {
  step: number
  totalSteps: number
}

const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ step, totalSteps }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {Array.from({ length: totalSteps }, (_, index) => (
        <div
          key={index}
          className="h-2 w-2 rounded-full mx-[5px]"
          style={{
            backgroundColor: index === step ? '#6172f3' : '#d0d5dd',
          }}
        />
      ))}
    </div>
  )
}

export default BreadCrumbs
