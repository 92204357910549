import { useSessionMaybe, useGrantMaybe } from '../session'
import theme from '../styles/theme'
import { Subscribe } from './Subscribe'
import JournalEntries from './JournalEntries'

export default function JournalIndex() {
  const session = useSessionMaybe()
  const grant = useGrantMaybe()
  const isSubscribed = grant?.minutes === -1

  // If user is logged out or is not subscribed, show subscribe content
  return (
    <div
      className={`${theme.panel.basic} text-center min-h-[calc(100vh-146px)] max-xs:px-0 max-xs:pt-0 max-xs:overflow-hidden max-xs:bg-white max-xs:grow-0 xs:grow md:w-full`}
    >
      <h2 className={`${theme.text.h2} mb-3 hidden xs:block`}>Journal</h2>
      <p className="text-xl text-textgray xs:mb-4">See yourself grow.</p>
      {!session || !isSubscribed ? (
        <Subscribe email={session?.user?.email} />
      ) : (
        <JournalEntries session={session} />
      )}
    </div>
  )
}
