import React from 'react'
import { HiCheck } from 'react-icons/hi2'

type BulletListProps = {
  items: any[]
  color?: string
  className?: string
  liClassName?: string
  numberList?: boolean
  checkClassName?: string
}

const BulletList: React.FC<BulletListProps> = ({
  items,
  color,
  className,
  liClassName,
  numberList,
  checkClassName,
}) => {
  return (
    <ul
      className={`flex flex-col items-start gap-4 text-[18px] font-[400] text-[#333] leading-[24px] ${
        className || ''
      }`}
    >
      {items.map((item, index) => (
        <li key={index} className={`flex ${liClassName || ''}`}>
          {numberList ? (
            <NumberBullet className="mr-4" color={color} numeral={index + 1} />
          ) : (
            <CheckBullet className={`mr-4 ${checkClassName}`} color={color} />
          )}
          <div>{item}</div>
        </li>
      ))}
    </ul>
  )
}

export default BulletList

const CheckBullet = ({
  className,
  color,
}: {
  className?: string
  color?: string
}) => {
  const bg = color === 'blurple' ? 'blurple' : 'ltgray'
  const fg = color === 'blurple' ? 'white' : 'black'
  return (
    <div
      className={`rounded-[16px] bg-${bg} flex items-center justify-center ${className}`}
    >
      <HiCheck className={`h-4 w-4 text-${fg}`} />
    </div>
  )
}

const NumberBullet = ({
  className,
  color,
  numeral,
}: {
  className?: string
  color?: string
  numeral: number
}) => {
  const bg = color === 'blurple' ? 'blurple' : 'ltgray'
  const fg = color === 'blurple' ? 'white' : 'black'
  return (
    <div
      className={`rounded-[16px] bg-${bg} flex items-center justify-center h-[32px] w-[32px] min-w-[32px] ${className}`}
    >
      <span className={`text-white text-${fg} font-satoshi font-[500]`}>
        {numeral}
      </span>
    </div>
  )
}
