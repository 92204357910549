import React, { useEffect, useRef } from 'react'
import type Recorder from '../conversation/recorder'

interface AudioVisualizerProps {
  recorder: Recorder
}

const DAMPING_CUTOFF = 0.3 // max 1
const DAMPING_POST_CUTOFF_MULTIPLIER = 1.4

const AudioVisualizer: React.FC<AudioVisualizerProps> = ({ recorder }) => {
  const audioContext = new AudioContext()
  const analyser = audioContext.createAnalyser()
  const visualValueCount = 16
  const visualElementsRef = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    let animationFrameId: number
    let isMounted = true
    const source = audioContext.createMediaStreamSource(recorder.audioStream)
    source.connect(analyser)
    analyser.smoothingTimeConstant = 0.5
    analyser.fftSize = 32

    const frequencyData = new Uint8Array(analyser.frequencyBinCount)
    const dataMap: Record<number,number> = {
      0: 15,
      1: 10,
      2: 8,
      3: 9,
      4: 6,
      5: 5,
      6: 2,
      7: 1,
      8: 0,
      9: 4,
      10: 3,
      11: 7,
      12: 11,
      13: 12,
      14: 13,
      15: 14,
    }

    const renderFrame = () => {
      if (!isMounted) return
      analyser.getByteFrequencyData(frequencyData)
      const values = Object.values(frequencyData)

      for (let i = 0; i < visualValueCount; i++) {
        //@ts-ignore
        const value =
          Math.max(values[dataMap[i]] / 255 - DAMPING_CUTOFF, 0) *
          DAMPING_POST_CUTOFF_MULTIPLIER
        const elmStyles = visualElementsRef!.current[i]?.style
        if (elmStyles) {
          elmStyles.transform = `scaleY(${value})`
          elmStyles.opacity = Math.max(0.25, value).toString()
        }
      }

      requestAnimationFrame(renderFrame)
    }

    animationFrameId = requestAnimationFrame(renderFrame)

    return () => {
      isMounted = false
      cancelAnimationFrame(animationFrameId) // Cancel the animation frame in the cleanup function
    }
  }, [recorder, analyser])

  return (
    <div className="w-[100%] h-[100%]">
      {Array.from({ length: visualValueCount }, (_, i) => (
        <div
          ref={el => (visualElementsRef.current[i] = el!)}
          key={i}
          style={{
            display: 'inline-block',
            width: '1.5px',
            height: '100%',
            margin: '0 4.5px 0 0',
            background: 'currentColor',
            transform: 'scaleY(.5)',
            opacity: '.25',
          }}
        ></div>
      ))}
    </div>
  )
}

export default AudioVisualizer
